import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/config";
import LoadingSpinner from "../components/shared/LoadingSpinner";

const ContactSupplierChatPage = () => {
  const { currentUser } = useAuth();
  const { chatId } = useParams(); // Get chatId from route params
  const location = useLocation(); // Access state passed through navigation
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatDetails, setChatDetails] = useState(null);
  const [buyerName, setBuyerName] = useState("Unknown Buyer");
  const [supplierName, setSupplierName] = useState("Unknown Supplier");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) return;

    const fetchChat = async () => {
      try {
        const chatRef = doc(db, "chats", chatId);
        const chatSnap = await getDoc(chatRef);

        if (chatSnap.exists()) {
          const chatData = chatSnap.data();
          setChatDetails(chatData);

          // Set supplierName from chatDetails
          if (chatData.supplierName) {
            setSupplierName(chatData.supplierName);
          } else {
            // Fallback: Fetch supplierName from Firestore users collection
            const supplierRef = doc(db, "users", chatData.supplierId);
            const supplierSnap = await getDoc(supplierRef);
            if (supplierSnap.exists()) {
              setSupplierName(supplierSnap.data().name || "Unknown Supplier");
            }
          }

          // Use buyerName from location.state if available
          if (location.state?.buyerName) {
            setBuyerName(location.state.buyerName);
          } else {
            // Fallback: Fetch buyerName from the carts collection
            const cartRef = doc(db, "carts", currentUser.uid);
            const cartSnap = await getDoc(cartRef);
            if (cartSnap.exists()) {
              setBuyerName(
                cartSnap.data().buyerName ||
                  currentUser.displayName ||
                  "Unknown Buyer"
              );
            }
          }
        } else {
          console.error("Chat not found.");
        }
      } catch (error) {
        console.error("Error fetching chat:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChat();
  }, [chatId, currentUser, location.state]);

  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = onSnapshot(doc(db, "chats", chatId), (snapshot) => {
      if (snapshot.exists()) {
        setMessages(snapshot.data().messages || []);
      }
    });

    return () => unsubscribe();
  }, [chatId, currentUser]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const chatRef = doc(db, "chats", chatId);

      await updateDoc(chatRef, {
        messages: arrayUnion({
          senderId: currentUser.uid,
          senderName: buyerName, // Use buyerName fetched or passed
          message: newMessage,
          timestamp: new Date().toISOString(),
        }),
      });

      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!chatDetails) {
    return <p>Chat not found. Please try again.</p>;
  }

  return (
    <div className='container my-4'>
      <h2>Contact Supplier: {supplierName}</h2>
      <p>Product: {chatDetails.productDetails.name}</p>

      <h3>Cart Details</h3>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Image</th>
            <th>Item</th>
            <th>Color</th>
            <th>Size</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Shipping</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {chatDetails.cartItems.map((item, index) => (
            <tr key={index}>
              <td>
                <img
                  src={item.mainImageUrl || "https://via.placeholder.com/100"}
                  alt={item.name}
                  className='img-thumbnail'
                  style={{ maxWidth: "50px", maxHeight: "50px" }}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.color || "Not selected"}</td>
              <td>{item.size || "Not selected"}</td>
              <td>{item.quantity}</td>
              <td>${item.price.toFixed(2)}</td>
              <td>${item.shippingCost.toFixed(2)}</td>
              <td>
                ${(item.quantity * item.price + item.shippingCost).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className='chat-box'
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          border: "1px solid #ccc",
          padding: "1rem",
          borderRadius: "5px",
        }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              textAlign: msg.senderId === currentUser.uid ? "right" : "left",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "inline-block",
                padding: "0.5rem 1rem",
                borderRadius: "10px",
                backgroundColor:
                  msg.senderId === currentUser.uid ? "#d1e7dd" : "#f8d7da",
                maxWidth: "70%",
              }}
            >
              <strong>{msg.senderName}</strong>
              <p style={{ margin: "0.5rem 0" }}>{msg.message}</p>
              <small>{new Date(msg.timestamp).toLocaleString()}</small>
            </div>
          </div>
        ))}
      </div>

      <div className='mt-3'>
        <textarea
          className='form-control'
          rows='3'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder='Type your message here...'
        ></textarea>
        <button className='btn btn-primary mt-2' onClick={handleSendMessage}>
          Send Message
        </button>
      </div>
    </div>
  );
};

export default ContactSupplierChatPage;
