import React, { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import logo from "../assets/logo.svg";
import Notification from "../components/shared/Notification";

const AdminLoginRegister = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const ALLOWED_ADMIN_EMAIL = "marsos@ayn-almanal.com";

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handleLogin = async () => {
    const auth = getAuth();
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;

      if (user.email !== ALLOWED_ADMIN_EMAIL) {
        showNotification(
          "Access denied",
          "You are not authorized as an admin."
        );
        navigate("/404");
        return;
      }

      const docRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(docRef);

      if (userDoc.exists()) {
        const { role, name } = userDoc.data();

        if (role === "admin") {
          localStorage.setItem(
            "user",
            JSON.stringify({
              uid: user.uid,
              name: name || "",
              email: user.email,
              role,
            })
          );
          showNotification("Admin logged in successfully");
          navigate("/admin-dashboard");
        } else {
          showNotification("Access denied: You do not have admin privileges.");
        }
      } else {
        showNotification("Access denied: User role not found.");
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      showNotification("Login failed", error.message);
    }
  };

  const handleForgotPassword = async () => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      showNotification(
        "Password Reset Email Sent",
        "Please check your email to reset your password."
      );
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Error during password reset:", error.message);
      showNotification("Password Reset Failed", error.message);
    }
  };

  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{
        minHeight: "100vh",
        marginTop: "-50px", // Adjust to move content upward
        backgroundColor: "#f7f7f7",
      }}
    >
      <div
        className='card p-3 shadow-sm'
        style={{
          maxWidth: "350px",
          width: "100%",
        }}
      >
        <div className='text-center mb-3'>
          <img
            src={logo}
            alt='Logo'
            style={{
              width: "70px",
              marginBottom: "10px",
            }}
          />
        </div>
        <h5 className='text-center mb-2'>
          {isForgotPassword ? "Reset Password" : "Admin Login"}
        </h5>
        <div className='form-group mb-2'>
          <input
            type='email'
            className='form-control form-control-sm'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {!isForgotPassword && (
          <div className='form-group mb-2'>
            <input
              type='password'
              className='form-control form-control-sm'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        )}
        <button
          className='btn btn-success btn-sm w-100 mb-2'
          onClick={isForgotPassword ? handleForgotPassword : handleLogin}
        >
          {isForgotPassword ? "Send Reset Email" : "Login"}
        </button>
        {!isForgotPassword && (
          <div className='text-center'>
            <button
              className='btn btn-link btn-sm'
              onClick={() => {
                setIsForgotPassword(true);
              }}
            >
              Forgot Password?
            </button>
          </div>
        )}
      </div>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={5000}
      />
    </div>
  );
};

export default AdminLoginRegister;
