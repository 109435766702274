import React, { useEffect } from "react";
import { Instagram, Linkedin, Twitter, Globe } from "react-feather";
import saudibusineslogo from "../../assets/saudi_business_logo.svg";
import tamaralogo from "../../assets/tamara.png";
import tabbylogo from "../../assets/tabby.png";
import madalogo from "../../assets/mada.png";
import mastercardlogo from "../../assets/mastercard.png";
import visalogo from "../../assets/visa.png";
import applepaylogo from "../../assets/applepay.png";

const Footer = () => {
  useEffect(() => {
    // Dynamically add the GoGetSSL script
    const script = document.createElement("script");
    script.src =
      "https://gogetssl-cdn.s3.eu-central-1.amazonaws.com/site-seals/gogetssl-seal.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      // Cleanup the script if the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <footer
      style={{
        backgroundColor: "#2c6449",
        color: "#fff",
        padding: "2rem 0",
      }}
    >
      <div className='container'>
        <div className='row'>
          {/* Contact Information */}
          <div className='col-md-4 col-12'>
            <h5>Marsos eCommerce Platform</h5>
            <p>Powered by Marsos Development Team</p>
            <p>
              Kingdom of Saudi Arabia - Riyadh - Al Rayyan - Eastern Ring Road
              14211, KSA
            </p>
          </div>

          {/* Site Map */}
          <div className='col-md-3 col-6'>
            <ul className='list-unstyled'>
              <li>Home</li>
              <li>Featured Products</li>
              <li>Categories</li>
              <li>Contact</li>
            </ul>
          </div>

          {/* Invest Links */}
          <div className='col-md-3 col-6'>
            <ul className='list-unstyled'>
              <li>Become a Supplier</li>
              <li>Login as Employee</li>
              <li>Get the App</li>
              <li>Help Center</li>
            </ul>
          </div>

          {/* Miscellaneous Links */}
          <div className='col-md-2 col-12 mt-md-0 mt-3'>
            <ul className='list-unstyled'>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>Subscribe Newsletter</li>
            </ul>
          </div>
        </div>

        {/* Social and Payment Icons Row */}
        <div className='row mt-4'>
          <div className='col-md-6 col-12 mb-md-0 mb-3'>
            <div>
              <p>Follow us on:</p>
              <div>
                <a
                  href='https://www.tiktok.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                    margin: "0 10px",
                  }}
                >
                  <Globe />
                </a>
                <a
                  href='https://www.instagram.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                    margin: "0 10px",
                  }}
                >
                  <Instagram />
                </a>
                <a
                  href='https://www.linkedin.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                    margin: "0 10px",
                  }}
                >
                  <Linkedin />
                </a>
                <a
                  href='https://twitter.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                    margin: "0 10px",
                  }}
                >
                  <Twitter />
                </a>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12 text-md-end text-center'>
            <div>
              {/* Top Image */}
              <img
                src={saudibusineslogo}
                alt='Top Banner'
                className='img-fluid mb-3'
                style={{
                  maxWidth: "50%",
                }}
              />
              {/* Payment Icons */}
              <div className='d-inline-flex flex-wrap justify-content-center justify-content-md-end'>
                <img
                  src={tamaralogo}
                  alt='Tamara'
                  className='img-fluid'
                  style={{ margin: "5px", width: "60px" }}
                />
                <img
                  src={tabbylogo}
                  alt='Tabby'
                  className='img-fluid'
                  style={{ margin: "5px", width: "60px" }}
                />
                <img
                  src={mastercardlogo}
                  alt='Master Card'
                  className='img-fluid'
                  style={{ margin: "5px", width: "60px" }}
                />
                <img
                  src={visalogo}
                  alt='Visa'
                  className='img-fluid'
                  style={{ margin: "5px", width: "60px" }}
                />
                <img
                  src={applepaylogo}
                  alt='Apple Pay'
                  className='img-fluid'
                  style={{ margin: "5px", width: "60px" }}
                />
                <img
                  src={madalogo}
                  alt='Mada'
                  className='img-fluid'
                  style={{ margin: "5px", width: "60px" }}
                />
              </div>

              {/* GoGetSSL Seal */}
              <div className='text-md-end text-center mt-3'>
                <a
                  href='https://www.gogetssl.com'
                  rel='nofollow'
                  title='GoGetSSL Site Seal Logo'
                >
                  <div
                    id='gogetssl-animated-seal'
                    style={{
                      width: "180px",
                      height: "58px",
                      display: "inline-block",
                    }}
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
