import React, { useRef, useEffect, useState, useMemo } from "react";

const AutocompleteInput = ({ value, onPlaceSelected, placeholder }) => {
  const inputRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Memoize saudiRegions
  const saudiRegions = useMemo(
    () => [
      "Riyadh Region",
      "Makkah Region",
      "Medina Region",
      "Eastern Province",
      "Asir Region",
      "Tabuk Region",
      "Hail Region",
      "Northern Borders Region",
      "Jazan Region",
      "Najran Region",
      "Al Bahah Region",
      "Al Jawf Region",
      "Qassim Region",
    ],
    []
  );

  useEffect(() => {
    // Check if the Google Maps script has been loaded
    const checkGoogleScript = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        setIsLoaded(true);
      } else {
        console.error("Google Maps script not loaded.");
      }
    };

    // Ensure the script is loaded
    if (!window.google) {
      console.error("Google API script is missing.");
    } else {
      checkGoogleScript();
    }
  }, []);

  useEffect(() => {
    if (!isLoaded || !inputRef.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["geocode"], // Restrict to address suggestions
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (place.address_components) {
        const address = place.formatted_address || "";
        const cityComponent = place.address_components.find((comp) =>
          comp.types.includes("locality")
        );
        const regionComponent = place.address_components.find(
          (comp) =>
            comp.types.includes("administrative_area_level_1") ||
            comp.types.includes("political")
        );

        const regionMatch = saudiRegions.find(
          (r) => r.toLowerCase() === regionComponent?.long_name?.toLowerCase()
        );

        onPlaceSelected({
          address,
          city: cityComponent?.long_name || "",
          region: regionMatch || regionComponent?.long_name || "",
        });
      }
    });
  }, [isLoaded, onPlaceSelected, saudiRegions]);

  return (
    <input
      ref={inputRef}
      type='text'
      value={value}
      onChange={(e) => onPlaceSelected({ address: e.target.value })}
      placeholder={placeholder}
      className='form-control'
    />
  );
};

export default AutocompleteInput;
