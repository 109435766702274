import React from "react";
import { Mail, MapPin, Phone } from "react-feather"; // Importing Feather icons
import { useTranslation } from "react-i18next"; // Import react-i18next

const TopBar = () => {
  const { t } = useTranslation(); // Hook for translation
  const borderColor = "rgba(44, 100, 73, 0.2)"; // Lightened color for the border
  const iconColor = "#2c6449"; // Consistent color for the icons

  return (
    <div
      className='bg-light py-2'
      style={{
        borderBottom: `1px solid ${borderColor}`, // Light and full-width border
        color: iconColor,
      }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          {/* Left Side: Contact Information */}
          <div className='col-12 col-md-6 text-center text-md-start mb-2 mb-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center'>
              <span className='me-3 d-flex align-items-center'>
                <Mail
                  size={16}
                  style={{ marginRight: "5px", color: iconColor }}
                />
                {t("topbar.email")}
              </span>
              <span
                className='me-3 d-flex align-items-center'
                style={{ marginLeft: "10px" }}
              >
                <Phone size={16} />
                {t("topbar.phone")}
              </span>
              <span className='d-flex align-items-center mt-2 mt-md-0'>
                {t("topbar.becomeSupplier")}
              </span>
            </div>
          </div>

          {/* Right Side: Language Selector and Location Detector */}
          <div className='col-12 col-md-6 text-center text-md-end'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-end'>
              {/* Location Detector */}
              <div className='d-flex align-items-center'>
                <MapPin
                  size={16}
                  style={{ marginRight: "5px", color: iconColor }}
                />
                <span>{t("topbar.yourLocation")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
