import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { getAuth } from "firebase/auth";

// Import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Notification from "../../components/shared/Notification";
import { v4 as uuidv4 } from "uuid";
import { useCart } from "../../context/CartContext";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  // User-selected options
  const [quantity, setQuantity] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [minOrderError, setMinOrderError] = useState("");

  const { setCartItemCount } = useCart(); // Use the cart context

  // Fetch current user from localStorage
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = () => {
      const userId = localStorage.getItem("userId");
      const userName = localStorage.getItem("userName");
      const userRole = localStorage.getItem("userRole");

      if (userId && userName && userRole) {
        setCurrentUser({ userId, userName, userRole });
      } else {
        setCurrentUser(null);
      }
    };

    fetchCurrentUser();

    // Add an event listener for storage changes (e.g., login/logout elsewhere)
    const handleStorageChange = () => fetchCurrentUser();
    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "products", id);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const productData = productSnap.data();

          setProduct(productData);
        } else {
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const calculateSubtotal = () => {
    if (
      !quantity ||
      isNaN(quantity) ||
      quantity <= 0 ||
      !product?.priceRanges
    ) {
      return 0;
    }

    const qty = Number(quantity);

    const matchingRange = product.priceRanges.find(
      (range) =>
        qty >= range.minQty &&
        (range.maxQty === undefined || qty <= range.maxQty)
    );

    if (!matchingRange) {
      const lastRange = product.priceRanges[product.priceRanges.length - 1];
      return lastRange.price * qty;
    }

    return matchingRange.price * qty;
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setQuantity(value);

      const firstRange = product?.priceRanges?.[0];
      if (firstRange && value && Number(value) < firstRange.minQty) {
        setMinOrderError(`The minimum order is ${firstRange.minQty} pieces.`);
      } else {
        setMinOrderError("");
      }
    }
  };

  const handleContactSupplier = (supplierId, productId) => {
    if (!currentUser) {
      showNotification(
        "Login Required",
        "You must be logged in to contact the supplier."
      );
      return;
    }
    const chatDocId = `chat_${currentUser.userId}_${supplierId}`;
    const chatUrl = `/chat/${chatDocId}?supplierId=${supplierId}&productId=${productId}`;
    window.open(chatUrl, "_blank");
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    setSelectedLocation(location);

    const selectedLocObj = product?.priceRanges?.[0]?.locations?.find(
      (loc) => loc.location === location
    );
    setShippingCost(selectedLocObj?.locationPrice || 0);
  };

  const handleAddToCart = async () => {
    if (!quantity || isNaN(quantity) || quantity <= 0) {
      showNotification("Invalid Input", "Please enter a valid quantity.");
      return;
    }

    if (!selectedColor || !selectedSize) {
      showNotification(
        "Incomplete Selection",
        "Please select both a color and a size."
      );
      return;
    }

    const cartId = uuidv4();

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        showNotification(
          "Login Required",
          "You must be logged in to add items to the cart."
        );
        return;
      }

      const userId = currentUser.uid;

      // Query the users collection to get the buyer's name
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      let buyerName = "Unknown Buyer";
      if (userDoc.exists()) {
        buyerName = userDoc.data().name || "Unknown Buyer";
      }

      const cartItem = {
        cartId,
        productId: id,
        name: product.productName,
        mainImageUrl: product.mainImageUrl || "https://via.placeholder.com/300",
        price: calculateSubtotal() / quantity,
        quantity: Number(quantity),
        color: selectedColor,
        size: selectedSize,
        shippingCost,
        deliveryLocation: selectedLocation,
        supplierName: product.supplierName || "Unknown Supplier",
        supplierId: product.supplierId || null,
        addedAt: Timestamp.now(),
        buyerId: userId,
        buyerName,
      };

      const cartRef = doc(db, "carts", userId);
      const cartSnap = await getDoc(cartRef);

      if (cartSnap.exists()) {
        await updateDoc(cartRef, {
          items: arrayUnion(cartItem),
          updatedAt: serverTimestamp(),
        });
      } else {
        await setDoc(cartRef, {
          items: [cartItem],
          buyerId: userId,
          buyerName,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }

      // Update the cart item count dynamically
      const updatedCartSnap = await getDoc(cartRef);
      if (updatedCartSnap.exists()) {
        const updatedCartData = updatedCartSnap.data();
        setCartItemCount(updatedCartData.items.length); // Update the cart count in context
      }

      showNotification("Success", "Product added to cart!");
    } catch (error) {
      console.error("Failed to add to cart:", error);
      showNotification(
        "Error",
        "Failed to add product to cart. Please try again."
      );
    }
  };

  if (loading) {
    return <p>Loading product details...</p>;
  }

  if (!product) {
    return <p>Product not found.</p>;
  }

  const availableLocations = product?.priceRanges?.[0]?.locations || [];

  return (
    <div className='container my-4'>
      <div className='row'>
        <div className='col-md-6'>
          {/* Swiper Slider */}
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Pagination, Autoplay]}
          >
            {[product.mainImageUrl, ...(product.additionalImageUrls || [])]
              .filter(
                // Combine mainImageUrl and additionalImageUrls
                Boolean
              )
              .map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    alt={`Product Slide ${index + 1}`}
                    className='img-fluid'
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/300"; // Fallback
                    }}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className='col-md-6'>
          {product.category ? `${product.category}` : "Uncategorized"}
          <h2>{product.productName || "Unnamed Product"}</h2>

          {product.supplierName && product.supplierId && (
            <p>
              <strong>Supplier:</strong>{" "}
              <a
                href={`/supplier/${product.supplierId}`}
                style={{ color: "#2c6449", textDecoration: "underline" }}
              >
                {product.supplierName}
              </a>
            </p>
          )}

          <p>{product.description || "No description available."}</p>
          <h4>Price Ranges</h4>
          <ul>
            {product.priceRanges?.map((range, index) => (
              <li key={index}>
                {range.minQty} - {range.maxQty || "∞"} Pieces: SR {range.price}
              </li>
            ))}
          </ul>

          <div className='row mb-3'>
            <div className='col-md-6'>
              <label>Wholesale Quantity:</label>
              <input
                type='text'
                className='form-control'
                value={quantity}
                onChange={handleQuantityChange}
                placeholder='Enter quantity'
              />
              {minOrderError && (
                <p className='text-danger small'>{minOrderError}</p>
              )}
            </div>
            <div className='col-md-6'>
              <label>Choose Color:</label>
              <select
                className='form-select'
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
              >
                <option value=''>Select Color</option>
                {product.colors?.map((color, index) => (
                  <option key={index} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-md-6'>
              <label>Choose Size:</label>
              <select
                className='form-select'
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                <option value=''>Select Size</option>
                {product.sizes?.map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-6'>
              <label>Delivery Location:</label>
              <select
                className='form-select'
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                <option value=''>Select Location</option>{" "}
                {availableLocations.map((loc, index) => (
                  <option key={index} value={loc.location}>
                    {loc.location} (${loc.locationPrice})
                  </option>
                ))}
              </select>
            </div>
          </div>

          <p>Shipping Cost: SR {shippingCost}</p>
          <h4>Subtotal: SR {calculateSubtotal()}</h4>
          <div className='mt-4 d-flex gap-3'>
            <button className='btn btn-primary' onClick={handleAddToCart}>
              Add to Cart
            </button>
            <button
              className='btn btn-secondary'
              onClick={() =>
                handleContactSupplier(product.supplierId, product.id)
              }
            >
              Contact Supplier
            </button>
          </div>
        </div>
      </div>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={2000} // Auto-close after 3 seconds
      />
    </div>
  );
};

export default ProductDetails;
