import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AdminLogin from "./auth/AdminLogin";
import BuyerSupplierLogin from "./auth/BuyerSupplierLogin";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import SupplierDashboard from "./components/dashboard/SupplierDashboard";
import BuyerDashboard from "./components/dashboard/BuyerDashboard";
import PrivateRoute from "./utils/PrivateRoute";
import Layout from "./components/shared/Layout";
import Register from "./auth/Register";
import SupplierRegistration from "./auth/SupplierRegistration";
import BuyerRegistration from "./auth/BuyerRegistration";
import Products from "./pages/Products";
import ProductDetails from "./components/shared/ProductDetails";
import CartPage from "./auth/CartPage";
import ChatPage from "./auth/ChatPage";
import CheckoutPage from "./auth/CheckOutPage";
import AdminAddProducts from "./auth/AdminAddProducts";
import AdminEditProducts from "./auth/AdminEditProducts";
import TermsAndConditions from "./components/shared/TermsAndConditions";
import PrivacyPolicy from "./components/shared/PrivacyPolicy";
import UpdatedPrivacyPolicy from "./pages/UpdatedPrivacyPolicy";
import UpdatedTermsAndConditions from "./pages/UpdatedTermsAndConditions";
import SupplierProductsPage from "./pages/SupplierProductsPage";
import Categories from "./pages/Categories";
import CategoryDetails from "./pages/CategoryDetails";
import SupplierEditProducts from "./auth/SupplierEditProducts";
import SupplierAddProducts from "./auth/SupplierAddProducts";
import EmployeeLoginPage from "./pages/EmpoyeeLoginPage";
import EmployeeDashboard from "./components/dashboard/EmployeeDashboard";
import PlasticAndPapersSubcategories from "./pages/PlasticAndPapersSubcategories";
import NotFound from "./pages/NotFound";
import SaudiManufacturedSubcategories from "./pages/SaudiManufacturedSubcategories";
import BuyerChatPage from "./auth/BuyerChatPage";
import SupplierChatPage from "./auth/SupplierChatPage";
import ConstructionSubcategories from "./pages/ConstructionSubcategories ";
import EquipmentSubcategories from "./pages/EquipmentSubcategories";

export const AppRoutes = () => {
  const [userRole, setUserRole] = useState(null);
  const [supplierId, setSupplierId] = useState("");
  return (
    <Routes>
      {/* Unprotected Route */}
      <Route
        path='/'
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path='/404'
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
      <Route
        path='/terms-and-conditions'
        element={
          <Layout>
            <TermsAndConditions />
          </Layout>
        }
      />
      <Route
        path='/updated-terms-and-conditions'
        element={
          <Layout>
            <UpdatedTermsAndConditions />
          </Layout>
        }
      />
      <Route
        path='/privacy-policy'
        element={
          <Layout>
            <PrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path='/updated-privacy-policy'
        element={
          <Layout>
            <UpdatedPrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path='/user-login'
        element={
          <Layout>
            <BuyerSupplierLogin />
          </Layout>
        }
      />
      <Route
        path='/register'
        element={
          <Layout>
            <Register />
          </Layout>
        }
      />
      <Route
        path='/supplier'
        element={
          <Layout>
            <SupplierRegistration />
          </Layout>
        }
      />
      <Route
        path='/buyer'
        element={
          <Layout>
            <BuyerRegistration />
          </Layout>
        }
      />
      <Route
        path='/products'
        element={
          <Layout>
            <Products />
          </Layout>
        }
      />
      <Route
        path='/product/:id'
        element={
          <Layout>
            <ProductDetails />
          </Layout>
        }
      />
      <Route
        path='/categories'
        element={
          <Layout>
            <Categories />
          </Layout>
        }
      />
      <Route
        path='/construction'
        element={
          <Layout>
            <ConstructionSubcategories />
          </Layout>
        }
      />
      <Route
        path='/equipment'
        element={
          <Layout>
            <EquipmentSubcategories />
          </Layout>
        }
      />
      <Route
        path='/plastic-and-papers'
        element={
          <Layout>
            <PlasticAndPapersSubcategories />
          </Layout>
        }
      />
      <Route
        path='/saudi-manufactured'
        element={
          <Layout>
            <SaudiManufacturedSubcategories />
          </Layout>
        }
      />
      <Route
        path='/categories/:id'
        element={
          <Layout>
            <CategoryDetails />
          </Layout>
        }
      />
      <Route
        path='/admin-login'
        element={
          <Layout>
            <AdminLogin />
          </Layout>
        }
      />
      <Route
        path='/employee-login'
        element={
          <Layout>
            <EmployeeLoginPage
              setUserRole={setUserRole}
              setSupplierId={setSupplierId}
            />
          </Layout>
        }
      />
      <Route
        path='/employee-dashboard'
        element={
          <Layout>
            <EmployeeDashboard userRole={userRole} supplierId={supplierId} />
          </Layout>
        }
      />

      {/* Protected Routes */}
      {/* ADMIN */}
      <Route
        path='/admin-dashboard'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/admin-add-products'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminAddProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/admin-edit-products/:productId'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminEditProducts />
            </PrivateRoute>
          </Layout>
        }
      />

      {/* SUPPLIER */}
      <Route
        path='/supplier-dashboard'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/supplier/:supplierId'
        element={
          <Layout>
            <SupplierProductsPage />
          </Layout>
        }
      />
      <Route
        path='/supplier-add-products'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierAddProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/supplier-edit-products/:productId'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierEditProducts />
            </PrivateRoute>
          </Layout>
        }
      />

      {/* BUYER */}
      <Route
        path='/cart'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <CartPage />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/checkout'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <CheckoutPage />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/buyer-dashboard'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <BuyerDashboard />
            </PrivateRoute>
          </Layout>
        }
      />

      {/* SUPPLIER AND BUYER */}
      <Route
        path='/chat/:chatId'
        element={
          <Layout>
            <PrivateRoute roles={["supplier", "buyer"]}>
              <ChatPage />
            </PrivateRoute>
          </Layout>
        }
      />

      <Route
        path='/buyer/chat/:chatId'
        element={
          <Layout>
            <BuyerChatPage />
          </Layout>
        }
      />

      <Route
        path='/supplier/chat/:chatId'
        element={
          <Layout>
            <SupplierChatPage />
          </Layout>
        }
      />
      <Route
        path='/checkout'
        element={
          <Layout>
            <CheckoutPage />
          </Layout>
        }
      />
    </Routes>
  );
};
