import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/config";
import { useAuth } from "../context/AuthContext";

const Products = () => {
  const { hasRole, loading, role, userData } = useAuth();
  const [productData, setProductData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (loading || !hasRole("supplier")) return;

        const supplierId = userData?.uid || userData?.supplierId;
        if (!supplierId) {
          console.error(
            "Both supplierId and uid are missing in userData:",
            userData
          );
          return;
        }

        const productsRef = collection(db, "products");
        const supplierQuery = query(
          productsRef,
          where("supplierId", "==", supplierId)
        );
        const querySnapshot = await getDocs(supplierQuery);

        const products = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProductData(products);

        const uniqueCategories = [
          "All",
          ...new Set(
            products.map((product) => product.category || "Uncategorized")
          ),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching supplier-specific products:", error);
      }
    };

    fetchProducts();
  }, [loading, hasRole, userData]);

  const handleDelete = async (productId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "products", productId));
        setProductData((prev) =>
          prev.filter((product) => product.id !== productId)
        );
        alert("Product deleted successfully!");
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Failed to delete product.");
      }
    }
  };

  const filteredProducts =
    selectedTab === "All"
      ? productData
      : productData.filter((product) => product.category === selectedTab);

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage); // Calculate total pages
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  if (!loading && role !== "supplier") {
    return <div>You are not authorized to view this page.</div>;
  }

  return (
    <div className='container-fluid py-3'>
      {/* Header */}
      <div className='mb-2'>
        <h4 className='text-success fw-bold'>Products</h4>
        <p className='text-muted small'>All Products</p>
      </div>

      {/* Dynamic Tabs */}
      <div className='mb-2 d-flex align-items-center'>
        {categories.map((category, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedTab(category);
              setCurrentPage(1); // Reset to page 1 when changing category
            }}
            className={`btn btn-link text-decoration-none me-2 small ${
              selectedTab === category ? "text-success fw-bold" : "text-muted"
            }`}
          >
            {category}{" "}
            <span className='badge bg-light text-dark ms-1 small'>
              {category === "All"
                ? productData.length
                : productData.filter((product) => product.category === category)
                    .length}
            </span>
          </button>
        ))}
      </div>

      {/* Search and Filter Section */}
      <div className='d-flex align-items-center mb-2 small'>
        <select
          className='form-select form-select-sm me-2'
          style={{ maxWidth: "150px" }}
        >
          <option value='manual'>Location</option>
          <option value='price'>Price</option>
          <option value='quantity'>Quantity</option>
        </select>
        <button className='btn btn-outline-primary btn-sm me-2'>
          Filter Products
        </button>
        <input
          type='text'
          className='form-control form-control-sm me-2'
          placeholder='Search by product name, or product SKU'
          style={{ maxWidth: "300px" }}
        />
        <button className='btn btn-primary btn-sm'>Search</button>
      </div>

      {/* Export & Additional Options */}
      <div className='d-flex align-items-center justify-content-between mb-2 small'>
        <button className='btn btn-outline-primary btn-sm'>
          Export Products
        </button>
        <div>
          <button className='btn btn-outline-secondary btn-sm me-2'>
            Additional Options
          </button>
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate("/supplier-add-products")}
          >
            Add New Product
          </button>
        </div>
      </div>

      {/* Product Table */}
      <div className='table-responsive'>
        <table className='table table-striped table-hover table-sm'>
          <thead className='table-light small'>
            <tr>
              <th>
                <input type='checkbox' />
              </th>
              <th>Product</th>
              <th>Name</th>
              <th>Supplier Name</th>
              <th>Location</th>
              <th>Qty & Pricing</th>
              <th>Size</th>
              <th>Color</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.map((product) => (
              <tr key={product.id}>
                <td>
                  <input type='checkbox' />
                </td>
                <td>
                  <img
                    src={
                      product.mainImageUrl || "https://via.placeholder.com/50"
                    }
                    alt={product.productName || "Product Image"}
                    className='img-fluid rounded'
                    style={{ width: "40px", height: "40px" }}
                  />
                </td>
                <td className='small'>
                  {product.productName || "Unnamed Product"}
                </td>
                <td className='small'>{product.supplierName || "N/A"}</td>
                <td className='small'>{product.mainLocation || "N/A"}</td>
                <td className='small'>
                  {product.priceRanges?.length ? (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {product.priceRanges.map((range, index) => (
                        <li key={index}>
                          Min: {range.minQty}, Max: {range.maxQty}, Price: SAR{" "}
                          {range.price}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className='small'>
                  {product.sizes?.length ? (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {product.sizes.map((size, index) => (
                        <li key={index}>{size}</li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className='small'>
                  {product.colors?.length ? (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {product.colors.map((color, index) => (
                        <li key={index}>{color}</li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <div className='dropdown position-static'>
                    <button
                      className='btn btn-outline-secondary btn-sm dropdown-toggle'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      Actions
                    </button>
                    <ul className='dropdown-menu dropdown-menu-end'>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() =>
                            navigate(`/supplier-edit-products/${product.id}`)
                          }
                        >
                          Edit
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item text-danger'
                          onClick={() => handleDelete(product.id)}
                        >
                          Remove
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className='d-flex justify-content-between align-items-center mt-3'>
        <button
          className='btn btn-outline-secondary btn-sm'
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className='text-muted small'>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className='btn btn-outline-secondary btn-sm'
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Products;
