import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext"; // Import the custom AuthContext
import { MessageCircle, User, ShoppingCart, Search, Menu } from "react-feather"; // Importing icons
import { useNavigate, useLocation } from "react-router-dom"; // Import navigate and location
import logo from "../../assets/logo.svg";
import TopBar from "./TopBar";
import SecondaryNavbar from "./SecondaryNavbar";
import { useTranslation } from "react-i18next";
import { useCart } from "../../context/CartContext"; // Import the cart context

const Navbar = () => {
  const { currentUser, userData, loading, logout } = useAuth(); // Access AuthContext
  const navigate = useNavigate();
  const location = useLocation(); // Use location hook to get the current path
  const iconColor = "#2c6449"; // Define the consistent color
  const [showSecondaryNavbar, setShowSecondaryNavbar] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { cartItemCount } = useCart();

  // const [currency, setCurrency] = useState("USD");

  const { i18n } = useTranslation();

  const { t } = useTranslation();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language); // Change the language
    document.documentElement.dir = language === "ar" ? "rtl" : "ltr"; // Adjust text direction
  };

  useEffect(() => {
    document.documentElement.setAttribute(
      "dir",
      i18n.language === "ar" ? "rtl" : "ltr"
    );
  }, [i18n.language]);

  useEffect(() => {
    const handleScroll = () => {
      setShowSecondaryNavbar(window.scrollY > 100); // Show secondary navbar after scrolling 100px
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) {
    return (
      <div className='text-center'>
        <div className='spinner-border text-primary' role='status'></div>
      </div>
    );
  }

  // Handle User Icon Click
  const handleUserIconClick = () => {
    if (userData) {
      const { role } = userData;
      const currentPath = location.pathname;

      if (role === "buyer" && currentPath !== "/buyer-dashboard") {
        navigate("/buyer-dashboard");
      } else if (role === "supplier" && currentPath !== "/supplier-dashboard") {
        navigate("/supplier-dashboard");
      } else if (role === "admin" && currentPath !== "/admin-dashboard") {
        navigate("/admin-dashboard");
      } else {
        console.log(
          "User is already on the correct dashboard page or role is unhandled:",
          role
        );
      }
    } else {
      if (location.pathname !== "/user-login") {
        navigate("/user-login");
      }
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  return (
    <div>
      {/* Top Bar (Hidden on small screens) */}
      <div className='d-none d-md-block'>
        <TopBar />
      </div>

      {/* Main Navbar */}
      <nav className='navbar navbar-expand-lg bg-body-tertiary'>
        <div className='container'>
          {/* Logo */}
          <a className='navbar-brand' href='/'>
            <img
              src={logo}
              alt='Logo'
              style={{ height: "100px" }} // Adjust size as needed
            />
          </a>

          {/* Icons and Custom Hamburger Menu (Small Screens) */}
          <div className='d-flex align-items-center d-lg-none'>
            {/* Search Bar */}
            <div className='input-group me-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                className='btn btn-outline-secondary'
                type='button'
                onClick={handleSearch}
              >
                <Search size={16} />
              </button>
            </div>
            {/* Cart Icon */}
            <button
              className='btn btn-link me-2 position-relative'
              aria-label='Cart'
              style={{ color: iconColor }} // Use consistent iconColor
              onClick={() => navigate("/cart")}
            >
              <ShoppingCart size={20} style={{ color: iconColor }} />{" "}
              {/* Ensure color */}
              {cartItemCount > 0 && (
                <span
                  className='badge bg-danger rounded-circle position-absolute'
                  style={{ top: "0px", right: "-5px", fontSize: "0.8rem" }}
                >
                  {cartItemCount}
                </span>
              )}
            </button>
            {/* User Icon */}
            <div className='dropdown'>
              <button
                className='btn btn-link dropdown-toggle'
                type='button'
                id='userDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{ color: iconColor }}
              >
                <User size={20} />
              </button>
              <ul
                className='dropdown-menu dropdown-menu-end'
                aria-labelledby='userDropdown'
              >
                {currentUser && userData ? (
                  <>
                    <li>
                      <span
                        className='dropdown-item-text'
                        style={{
                          fontWeight: "bold",
                          color: iconColor,
                        }}
                      >
                        Hi, {userData.name || "User"}!
                      </span>
                    </li>
                    <li>
                      <hr className='dropdown-divider' />
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={handleUserIconClick}
                      >
                        My Dashboard
                      </button>
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={() => navigate("/orders")}
                      >
                        Order History
                      </button>
                    </li>
                    <li>
                      <hr className='dropdown-divider' />
                    </li>
                    <li>
                      <button className='dropdown-item' onClick={logout}>
                        {t("navbar.logout")}
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={() => navigate("/user-login")}
                      >
                        Login
                      </button>
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={() => navigate("/signup")}
                      >
                        Register
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {/* Custom Hamburger Menu */}
            <button
              className='btn btn-link ms-2'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarNavDropdown'
              aria-controls='navbarNavDropdown'
              aria-expanded='false'
              aria-label='Toggle navigation'
              style={{
                color: iconColor,
                border: "none",
                boxShadow: "none",
              }}
            >
              <Menu size={24} />
            </button>
          </div>

          {/* Navbar Content */}
          <div
            className='collapse navbar-collapse justify-content-between'
            id='navbarNavDropdown'
          >
            {/* Menu */}
            <ul
              className='navbar-nav me-auto justify-content-center flex-grow-1'
              style={{ color: iconColor }}
            >
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  aria-current='page'
                  href='/'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Home")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/features'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Featured Products")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/categories'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Categories")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/contact'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Contact")}
                </a>
              </li>
            </ul>

            {/* User Info */}
            <div className='d-flex align-items-center'>
              <div className='input-group me-3'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search...'
                  style={{
                    maxWidth: "300px",
                    border: "1px solid #2c6449", // Border color matches menus
                    color: "#2c6449", // Text color
                  }}
                />
              </div>
              <button
                className='btn btn-link me-2 position-relative'
                aria-label='Cart'
                style={{ color: iconColor }} // Use consistent iconColor
                onClick={() => navigate("/cart")}
              >
                <ShoppingCart size={20} style={{ color: iconColor }} />{" "}
                {/* Ensure color */}
                {cartItemCount > 0 && (
                  <span
                    className='badge bg-danger rounded-circle position-absolute'
                    style={{ top: "0px", right: "-5px", fontSize: "0.8rem" }}
                  >
                    {cartItemCount}
                  </span>
                )}
              </button>
              <button
                className='btn btn-link me-2'
                aria-label='Messages'
                style={{ color: iconColor }}
              >
                <MessageCircle size={20} />
              </button>
              <div className='dropdown'>
                <button
                  className='btn btn-link dropdown-toggle'
                  type='button'
                  id='userDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  style={{ color: iconColor }}
                >
                  <User size={20} />
                </button>
                <ul
                  className='dropdown-menu dropdown-menu-end'
                  aria-labelledby='userDropdown'
                >
                  {currentUser && userData ? (
                    <>
                      <li>
                        <span
                          className='dropdown-item-text'
                          style={{
                            fontWeight: "bold",
                            color: iconColor,
                          }}
                        >
                          Hi, {userData.name || "User"}!
                        </span>
                      </li>
                      <li>
                        <hr className='dropdown-divider' />
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={handleUserIconClick}
                        >
                          My Dashboard
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() => navigate("/orders")}
                        >
                          Order History
                        </button>
                      </li>
                      <li>
                        <hr className='dropdown-divider' />
                      </li>
                      <li>
                        <button className='dropdown-item' onClick={logout}>
                          {t("logout")}
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() => navigate("/user-login")}
                        >
                          Login
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() => navigate("/signup")}
                        >
                          Register
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              {/* Currency Selector */}

              {/* Language Toggle */}
              <div>
                <select
                  onChange={(e) => handleLanguageChange(e.target.value)}
                  defaultValue={i18n.language}
                  style={{
                    backgroundColor: "transparent", // Matches the rest of the navbar
                    color: iconColor, // Consistent color
                    border: "none", // Removes the border
                    outline: "none", // Removes focus outline
                    fontSize: "16px", // Adjust as needed for consistency
                    cursor: "pointer", // Makes it more user-friendly
                  }}
                >
                  <option value='en'>English</option>
                  <option value='ar'>العربية</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Secondary Navbar */}
      <div className='d-none d-md-block'>
        {showSecondaryNavbar && <SecondaryNavbar />}
      </div>
    </div>
  );
};

export default Navbar;
