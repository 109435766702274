import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/config";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const SupplierMessages = () => {
  const { t } = useTranslation();
  const { currentUser, userData, loading: authLoading } = useAuth();
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authLoading) return;

    if (!currentUser || !userData?.role || userData.role !== "supplier") {
      setError(t("notAuthorized"));
      setLoading(false);
      return;
    }

    const chatsRef = collection(db, "chats");
    const q = query(chatsRef, where("supplierId", "==", currentUser.uid));

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        const fetchedChats = [];
        for (const docSnap of snapshot.docs) {
          const chatData = docSnap.data();
          const buyerDoc = await getDoc(doc(db, "users", chatData.buyerId));
          const buyerName = buyerDoc.exists()
            ? buyerDoc.data().name
            : t("unknownBuyer");

          fetchedChats.push({
            id: docSnap.id,
            ...chatData,
            buyerName,
          });
        }
        setChats(fetchedChats);
        setLoading(false);
      },
      (err) => {
        console.error("Error fetching chats:", err);
        setError(t("fetchError"));
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser, userData, authLoading, t]);

  const handleChatClick = (chatId) => {
    navigate(`/supplier/chat/${chatId}`);
  };

  return (
    <div>
      <h4 className='text-success fw-bold'>{t("supplierChatsTitle")}</h4>
      {loading || authLoading ? (
        <p>{t("loadingChats")}</p>
      ) : error ? (
        <p className='text-danger'>{error}</p>
      ) : chats.length === 0 ? (
        <p>{t("noChatsFound")}</p>
      ) : (
        <ul className='list-group'>
          {chats.map((chat) => {
            const lastMessage =
              Array.isArray(chat.messages) && chat.messages.length > 0
                ? chat.messages[chat.messages.length - 1].message
                : t("noMessagesYet");

            return (
              <li
                key={chat.id}
                className='list-group-item'
                onClick={() => handleChatClick(chat.id)}
                style={{ cursor: "pointer" }}
              >
                <h6>{chat.buyerName}</h6>
                <p>
                  {t("lastMessage")}: {lastMessage}
                </p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SupplierMessages;
