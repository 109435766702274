import React, { useState } from "react";
import styled from "styled-components";
import Icon from "feather-icons-react";
import { useAuth } from "../../context/AuthContext";
import ManageProfiles from "../../auth/ManageProfiles";
import ManageEmployees from "../../auth/ManageEmployees";
import SupplierMessages from "../../auth/SupplierMessages";
import Products from "../../auth/SupplierProducts";
import { useTranslation } from "react-i18next";

// Styled-components for Sidebar with Parallax Effect
const Sidebar = styled.div`
  background-color: #f8f9fa;
  transition: all 0.5s ease-in-out;
  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(-20%)"};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  max-height: ${({ visible }) => (visible ? "100vh" : "0")};
  overflow: hidden;
  padding: ${({ visible }) => (visible ? "10px 20px" : "0")};
`;

const SidebarItem = styled.button`
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  color: ${({ active }) => (active ? "#2c6449" : "inherit")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    color: #2c6449;
  }
`;

const Navbar = styled.nav`
  background-color: Transparent;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
`;

const HamburgerButton = styled.button`
  border: none;
  background: none;
  color: #2c6449;
  cursor: pointer;

  &:hover {
    color: #14532d;
  }
`;

const Dashboard = () => {
  const { userData } = useAuth();
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("home");

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const { t } = useTranslation(); // Extract i18n

  // Define the menu items outside the function
  const menuItems = [
    { name: "home", icon: "home" },
    { name: "profiles", icon: "user" },
    { name: "terms", icon: "file-text" },
    { name: "employees", icon: "users" },
    { name: "messages", icon: "mail" },
    { name: "products", icon: "package" },
    { name: "orders", icon: "shopping-cart" },
    { name: "settings", icon: "settings" },
    { name: "support", icon: "help-circle" },
  ];

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);

    // Auto-close sidebar after selecting a menu item
    setSidebarVisible(false);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "home":
        return (
          <div>
            <h4>
              {t("dashboard.welcome", { name: userData?.name || "User" })}
            </h4>
            <p>{t("dashboard.description")}</p>
          </div>
        );
      case "profiles":
        return <ManageProfiles />;
      case "terms":
        return (
          <div>
            <h4>{t("dashboard.terms")}</h4>
            <p>{t("dashboard.terms_description")}</p>
          </div>
        );
      case "employees":
        return <ManageEmployees />;
      case "messages":
        return <SupplierMessages />;
      case "products":
        return <Products />;
      case "orders":
        return (
          <div>
            <h4>{t("dashboard.orders")}</h4>
            <p>{t("dashboard.orders_description")}</p>
          </div>
        );
      case "settings":
        return (
          <div>
            <h4>{t("dashboard.settings")}</h4>
            <p>{t("dashboard.settings_description")}</p>
          </div>
        );
      case "support":
        return (
          <div>
            <h4>{t("dashboard.support")}</h4>
            <p>{t("dashboard.support_description")}</p>
          </div>
        );
      default:
        return <h1>{t("dashboard.default_message")}</h1>;
    }
  };

  return (
    <div className='container-fluid'>
      <Navbar className='row'>
        <div className='col-6 d-flex align-items-center'>
          <HamburgerButton onClick={toggleSidebar}>
            <Icon icon='align-left' />
          </HamburgerButton>
          <h5 className='ms-2 mb-0'>{t("dashboard.title")}</h5>
        </div>
        <div className='col-6 d-flex justify-content-end align-items-center'>
          <img
            src={userData?.logoUrl || "https://via.placeholder.com/32"}
            alt='User Avatar'
            style={{ borderRadius: "50%", width: "60px", height: "60px" }}
          />
        </div>
      </Navbar>

      <div className='row'>
        {isSidebarVisible && (
          <div className='col-md-2'>
            <Sidebar visible={isSidebarVisible}>
              <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                {menuItems.map((menu) => (
                  <li key={menu.name}>
                    <SidebarItem
                      active={selectedMenu === menu.name}
                      onClick={() => handleMenuClick(menu.name)}
                    >
                      <Icon icon={menu.icon} />
                      {t(`menu.${menu.name}`)} {/* Translate menu name */}
                    </SidebarItem>
                  </li>
                ))}
              </ul>
            </Sidebar>
          </div>
        )}
        <div className={isSidebarVisible ? "col-md-10" : "col-12"}>
          <div style={{ padding: "20px" }}>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
