import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import { useAuth } from "../context/AuthContext";
import { File, Image, MapPin } from "feather-icons-react";

const BuyerChatPage = () => {
  const { chatId } = useParams();
  const { currentUser } = useAuth();
  const [cartItems, setCartItems] = useState([]);
  const [messages, setMessages] = useState([]);
  const [buyerName, setBuyerName] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchBuyerName = async (buyerId) => {
    try {
      const userRef = doc(db, "users", buyerId);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        setBuyerName(userData.displayName || buyerId);
      } else {
        setBuyerName(buyerId);
      }
    } catch (error) {
      console.error("Error fetching buyer name:", error);
      setBuyerName(buyerId);
    }
  };

  useEffect(() => {
    const chatRef = doc(db, "chats", chatId);

    const unsubscribe = onSnapshot(chatRef, async (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setCartItems(data.cartItems || []);
        setMessages(data.messages || []);

        if (data.buyerId) {
          await fetchBuyerName(data.buyerId);
        }
      } else {
        console.warn("Chat document does not exist.");
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [chatId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const chatRef = doc(db, "chats", chatId);

    try {
      await updateDoc(chatRef, {
        messages: arrayUnion({
          senderId: currentUser.uid,
          senderName: currentUser.displayName || "You",
          message: newMessage,
          timestamp: new Date().toISOString(),
        }),
      });

      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleUploadFile = () => {
    console.log("Upload File clicked");
  };

  const handleUploadImage = () => {
    console.log("Upload Image clicked");
  };

  const handleUploadLocation = () => {
    console.log("Upload Location clicked");
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className='container my-4'>
      <h2>Chat with Supplier</h2>
      {/* Cart Details */}
      <h3>Cart Details</h3>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Image</th>
            <th>Item</th>
            <th>Size</th>
            <th>Color</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Shipping</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <tr key={item.cartId}>
              <td>
                <img
                  src={item.mainImageUrl || "https://via.placeholder.com/100"}
                  alt={item.name}
                  style={{ width: "50px", height: "50px" }}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.size || "Not selected"}</td>
              <td>{item.color || "Not selected"}</td>
              <td style={{ width: "80px" }}>
                <input
                  type='number'
                  value={item.quantity}
                  min='1'
                  className='form-control form-control-sm'
                  disabled
                />
              </td>
              <td>${item.price.toFixed(2)}</td>
              <td>${(item.shippingCost || 0).toFixed(2)}</td>
              <td>
                $
                {(
                  item.quantity * item.price +
                  (item.shippingCost || 0)
                ).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Chatbox */}
      <div className='container my-4'>
        <h2 className='mb-4'>Messages</h2>

        <div
          className='chat-box border rounded p-3 mb-3 bg-light'
          style={{
            height: "350px",
            overflowY: "auto",
            backgroundColor: "#f9f9f9",
          }}
        >
          {messages.length ? (
            messages.map((msg, index) => (
              <div
                key={index}
                className={`d-flex flex-column mb-3 ${
                  msg.senderId === currentUser.uid
                    ? "align-items-end"
                    : "align-items-start"
                }`}
              >
                {/* Display sender name */}
                <div className='text-muted small mb-1'>
                  {msg.senderId === currentUser.uid
                    ? "You"
                    : msg.senderName || buyerName}
                </div>

                {/* Message Bubble */}
                <div className='bg-white border rounded px-3 py-2'>
                  <div>{msg.message}</div>
                  <div className='text-end mt-2'>
                    {new Date(msg.timestamp).toLocaleString()}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className='text-muted text-center'>No messages yet.</p>
          )}
        </div>

        {/* Input Section */}
        <div className='input-group'>
          <input
            type='text'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className='form-control'
            placeholder='Type your message...'
          />
          <button onClick={handleSendMessage} className='btn btn-primary'>
            Send
          </button>
          <div className='d-flex align-items-center ms-2'>
            <button
              className='btn btn-light'
              onClick={handleUploadFile}
              title='Upload File'
            >
              <File />
            </button>
            <button
              className='btn btn-light mx-2'
              onClick={handleUploadImage}
              title='Upload Image'
            >
              <Image />
            </button>
            <button
              className='btn btn-light'
              onClick={handleUploadLocation}
              title='Upload Location'
            >
              <MapPin />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerChatPage;
