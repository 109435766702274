import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  doc,
  onSnapshot,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/config";
import Notification from "../components/shared/Notification";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import ReviewOrderModal from "../auth/ReviewOrderModal";
import { useTranslation } from "react-i18next";

const CartPage = () => {
  const { currentUser } = useAuth(); // Fetch the current user
  const navigate = useNavigate(); // Hook for navigation
  const { t } = useTranslation(); // Translation hook
  const [cartItems, setCartItems] = useState([]); // Cart items from Firestore
  const [localCartItems, setLocalCartItems] = useState([]); // Local copy for edits
  const [loading, setLoading] = useState(true); // Loading spinner control
  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // Notification visibility
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false); // Review modal visibility
  const [selectedSupplierId, setSelectedSupplierId] = useState(null); // Supplier ID for modal

  const currencySymbol = t("cart.currency"); // Dynamic currency symbol

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  useEffect(() => {
    if (!currentUser?.uid) {
      setCartItems([]);
      setLoading(false);
      return;
    }

    const cartRef = doc(db, "carts", currentUser.uid);

    const unsubscribe = onSnapshot(cartRef, (snapshot) => {
      if (snapshot.exists()) {
        const items = snapshot.data().items || [];
        setCartItems(items);
        setLocalCartItems(items); // Sync Firestore data with local state
      } else {
        setCartItems([]);
        setLocalCartItems([]);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup the listener
  }, [currentUser]);

  const handleFieldChange = (cartId, field, value) => {
    setLocalCartItems((prevItems) =>
      prevItems.map((item) =>
        item.cartId === cartId ? { ...item, [field]: value } : item
      )
    );
  };

  const handleFieldBlur = async (cartId, field) => {
    const itemToUpdate = localCartItems.find((item) => item.cartId === cartId);

    if (["price", "shippingCost", "quantity"].includes(field)) {
      itemToUpdate.subtotal = (
        itemToUpdate.quantity * itemToUpdate.price +
        (itemToUpdate.shippingCost || 0)
      ).toFixed(2);
    }

    setCartItems(localCartItems);

    try {
      const cartRef = doc(db, "carts", currentUser.uid);
      await updateDoc(cartRef, { items: localCartItems });

      const chatRef = doc(
        db,
        "chats",
        `chat_${currentUser.uid}_${itemToUpdate.supplierId}`
      );
      await updateDoc(chatRef, { cartItems: localCartItems });

      showNotification(
        t("cart.notificationSuccess"),
        `${field} ${t("cart.updatedSuccessfully")}`
      );
    } catch (error) {
      console.error("Error updating field:", error);
      showNotification(
        t("cart.notificationError"),
        `${t("cart.failedToUpdate")} ${field}.`
      );
    }
  };

  const handleRemoveItem = async (cartId) => {
    const updatedCartItems = cartItems.filter((item) => item.cartId !== cartId);

    setCartItems(updatedCartItems);
    setLocalCartItems(updatedCartItems);

    try {
      const cartRef = doc(db, "carts", currentUser.uid);
      await updateDoc(cartRef, { items: updatedCartItems });

      showNotification(
        t("cart.notificationSuccess"),
        t("cart.itemRemovedSuccessfully")
      );
    } catch (error) {
      console.error("Error removing item:", error);
      showNotification(
        t("cart.notificationError"),
        t("cart.failedToRemoveItem")
      );
    }
  };

  const handleContactSupplier = async (supplierId, items) => {
    if (!currentUser) {
      showNotification(
        t("cart.notificationError"),
        t("cart.loginToContactSupplier")
      );
      navigate("/user-login");
      return;
    }

    const chatDocId = `chat_${currentUser.uid}_${supplierId}`;
    const chatRef = doc(db, "chats", chatDocId);

    try {
      const chatSnapshot = await getDoc(chatRef);
      const buyerName = currentUser.displayName || t("cart.unknownBuyer");

      if (chatSnapshot.exists()) {
        await updateDoc(chatRef, { cartItems: items });
      } else {
        await setDoc(chatRef, {
          chatId: chatDocId,
          buyerId: currentUser.uid,
          buyerName,
          supplierId,
          cartItems: items,
          messages: [],
          createdAt: serverTimestamp(),
        });
      }

      navigate(`/buyer/chat/${chatDocId}`, { state: { chatId: chatDocId } });
    } catch (error) {
      console.error("Error initiating chat:", error);
      showNotification(
        t("cart.notificationError"),
        t("cart.failedToContactSupplier")
      );
    }
  };

  const handleReviewOrder = (supplierId) => {
    setSelectedSupplierId(supplierId);
    setIsReviewModalOpen(true);
  };

  const closeReviewModal = () => {
    setSelectedSupplierId(null);
    setIsReviewModalOpen(false);
  };

  const handleCheckout = (supplierId) => {
    // Filter cart items for the specific supplier
    const supplierCartItems = localCartItems.filter(
      (item) => item.supplierId === supplierId
    );

    if (!supplierCartItems.length) {
      showNotification(
        t("cart.notificationError"),
        t("cart.noItemsForSupplier")
      );
      return;
    }

    // Navigate to the checkout page with the filtered items
    navigate("/checkout", {
      state: { cartItems: supplierCartItems, supplierId },
    });
  };

  const groupItemsBySupplier = (items) =>
    items.reduce((groups, item) => {
      const supplierName = item.supplierName || t("cart.unknownSupplier");
      if (!groups[supplierName]) groups[supplierName] = [];
      groups[supplierName].push(item);
      return groups;
    }, {});

  if (loading) return <LoadingSpinner />;
  if (!cartItems.length) return <p>{t("cart.emptyMessage")}</p>;

  const groupedItems = groupItemsBySupplier(localCartItems);

  return (
    <div className='container my-4'>
      <h2>{t("cart.title")}</h2>
      {Object.entries(groupedItems).map(([supplierName, items]) => (
        <div key={supplierName} className='mb-4'>
          <h4>
            {t("cart.supplier")}: {supplierName}
          </h4>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>{t("cart.image")}</th>
                <th>{t("cart.item")}</th>
                <th>{t("cart.size")}</th>
                <th>{t("cart.color")}</th>
                <th>{t("cart.quantity")}</th>
                <th>{t("cart.price")}</th>
                <th>{t("cart.shipping")}</th>
                <th>{t("cart.subtotal")}</th>
                <th>{t("cart.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.cartId}>
                  <td>
                    <img
                      src={
                        item.mainImageUrl || "https://via.placeholder.com/100"
                      }
                      alt={item.name}
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.size || t("cart.notSelected")}</td>
                  <td>{item.color || t("cart.notSelected")}</td>
                  <td>
                    <input
                      type='number'
                      value={item.quantity}
                      min='1'
                      onChange={(e) =>
                        handleFieldChange(
                          item.cartId,
                          "quantity",
                          parseInt(e.target.value, 10)
                        )
                      }
                      onBlur={() => handleFieldBlur(item.cartId, "quantity")}
                      className='form-control form-control-sm'
                      style={{ width: "80px" }} // Adjust width
                    />
                  </td>
                  <td>
                    <div
                      className='input-group input-group-sm'
                      style={{ width: "100px" }}
                    >
                      <span
                        className='input-group-text'
                        style={{ border: "none", background: "transparent" }}
                      >
                        {currencySymbol}
                      </span>
                      <input
                        type='number'
                        value={item.price}
                        onChange={(e) =>
                          handleFieldChange(
                            item.cartId,
                            "price",
                            parseFloat(e.target.value)
                          )
                        }
                        onBlur={() => handleFieldBlur(item.cartId, "price")}
                        className='form-control'
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      className='input-group input-group-sm'
                      style={{ width: "100px" }}
                    >
                      <span
                        className='input-group-text'
                        style={{ border: "none", background: "transparent" }}
                      >
                        {currencySymbol}
                      </span>
                      <input
                        type='number'
                        value={item.shippingCost || 0}
                        onChange={(e) =>
                          handleFieldChange(
                            item.cartId,
                            "shippingCost",
                            parseFloat(e.target.value)
                          )
                        }
                        onBlur={() =>
                          handleFieldBlur(item.cartId, "shippingCost")
                        }
                        className='form-control'
                      />
                    </div>
                  </td>

                  <td>
                    {currencySymbol}{" "}
                    {(
                      item.quantity * item.price +
                      (item.shippingCost || 0)
                    ).toFixed(2)}
                  </td>
                  <td>
                    {/* Checkout Button */}
                    <button
                      className='btn btn-success btn-sm'
                      style={{ marginInlineEnd: "10px", marginBottom: "10px" }}
                      onClick={() => handleCheckout(items[0]?.supplierId)}
                    >
                      {t("cart.checkout")}
                    </button>

                    {/* Actions Dropdown Button */}
                    <div
                      className='dropdown'
                      style={{ display: "inline-block" }}
                    >
                      <button
                        type='button'
                        className='btn btn-primary btn-sm dropdown-toggle'
                        style={{
                          marginInlineEnd: "10px",
                          marginBottom: "10px",
                        }}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {t("cart.actions")}
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <button
                            className='dropdown-item'
                            onClick={() => handleReviewOrder(item.supplierId)}
                          >
                            {t("cart.reviewOrder")}
                          </button>
                        </li>
                        <li>
                          <button
                            className='dropdown-item'
                            onClick={() =>
                              handleContactSupplier(item.supplierId, items)
                            }
                          >
                            {t("cart.contactSupplier")}
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* Remove Button */}
                    <button
                      className='btn btn-danger btn-sm'
                      style={{ marginBottom: "10px" }}
                      onClick={() => handleRemoveItem(item.cartId)}
                      title={t("cart.remove")}
                    >
                      &times;
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
      />
      <ReviewOrderModal
        isOpen={isReviewModalOpen}
        onClose={closeReviewModal}
        supplierId={selectedSupplierId}
      />
    </div>
  );
};

export default CartPage;
