import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Icon from "feather-icons-react";
import Products from "../../auth/AdminProducts";
import Suppliers from "../../auth/AdminSuppliersAdd";
import Settings from "../../auth/AdminSettings";
import BuyerList from "../../auth/BuyerList";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AdminDashboard = () => {
  // Initialize state with value from localStorage, default to "home"
  const [selectedPage, setSelectedPage] = useState(
    () => localStorage.getItem("selectedPage") || "home"
  );
  const [sidebarVisible, setSidebarVisible] = useState(true); // State to toggle sidebar visibility
  const navigate = useNavigate();

  // Save selectedPage to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedPage", selectedPage);
  }, [selectedPage]);

  // Check user authentication and role
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")); // Retrieve user from localStorage

    if (!user || user.role !== "admin") {
      // Redirect to login page if not authenticated or not admin
      navigate("/admin-login");
    }
  }, [navigate]);

  // Example chart data
  const chartData = {
    labels: ["Mar", "Jun", "Jul", "Aug", "Sep", "Oct", "Dec"],
    datasets: [
      {
        label: "Store Sales (SAR)",
        data: [0, 400, 1200, 800, 1600, 2000, 3534.57],
        backgroundColor: "rgba(104, 152, 247, 0.2)",
        borderColor: "rgba(104, 152, 247, 1)",
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "Sales Data",
      },
    },
  };

  // Render the main content dynamically based on the selected page
  const renderContent = () => {
    switch (selectedPage) {
      case "home":
        return (
          <div>
            <h4 className='text-success fw-bold'>Dashboard Overview</h4>
            <p>
              Welcome to the Admin Dashboard. Here you can manage various
              sections of the system.
            </p>
          </div>
        );
      case "analytics-overview":
        return (
          <div>
            <h4 className='text-success fw-bold'>Analytics Overview</h4>
            <Line data={chartData} options={chartOptions} />
          </div>
        );
      case "live-analytics":
        return (
          <div>
            <h4 className='text-success fw-bold'>Live Analytics</h4>
            <p>Real-time analytics for your store activity.</p>
          </div>
        );
      case "analytics-reports":
        return (
          <div>
            <h4 className='text-success fw-bold'>Analytics Reports</h4>
            <p>Download and view detailed analytics reports.</p>
          </div>
        );
      case "orders":
        return (
          <div>
            <h4 className='text-success fw-bold'>Orders</h4>
            <p>Manage and view all orders placed by customers.</p>
          </div>
        );
      case "abandoned-carts":
        return (
          <div>
            <h4 className='text-success fw-bold'>Abandoned Carts</h4>
            <p>Track and recover abandoned carts.</p>
          </div>
        );
      case "user-management-suppliers":
        return <Suppliers />;
      case "user-management-buyers":
        return <BuyerList />;
      case "user-management-admins":
        return (
          <div>
            <h4 className='text-success fw-bold'>Admins</h4>
            <p>Manage and view all admins.</p>
          </div>
        );
      case "products":
        return <Products />;
      case "options-library":
        return (
          <div>
            <h4 className='text-success fw-bold'>Options Library</h4>
            <p>Manage additional product options and configurations.</p>
          </div>
        );
      case "seo-enhancements":
        return (
          <div>
            <h4 className='text-success fw-bold'>SEO Enhancements</h4>
            <p>Enhance your website's search engine visibility.</p>
          </div>
        );
      case "settings":
        return <Settings />;
      default:
        return (
          <div>
            <h4 className='text-danger fw-bold'>Page Not Found</h4>
            <p>The page you are looking for does not exist.</p>
          </div>
        );
    }
  };

  return (
    <div className='container-fluid' style={{ backgroundColor: "#f7f7f7" }}>
      <div className='row'>
        {/* Sidebar Toggle Button */}
        <button
          className='btn btn-success d-md-none mb-3'
          onClick={() => setSidebarVisible(!sidebarVisible)}
        >
          Toggle Sidebar
        </button>

        {/* Sidebar */}
        {sidebarVisible && (
          <div
            className='col-md-3 bg-light py-4 d-none d-md-block'
            style={{ height: "100vh" }}
          >
            <h5 className='text-success fw-bold'>Admin Dashboard</h5>
            <div className='accordion' id='sidebarAccordion'>
              {/* Home Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingHome'>
                  <button
                    className={`accordion-button collapsed ${
                      selectedPage === "home" ? "active-link" : ""
                    }`}
                    type='button'
                    onClick={() => setSelectedPage("home")}
                  >
                    <Icon icon='home' className='me-2' /> Home
                  </button>
                </h2>
              </div>

              {/* User Management Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingUserManagement'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseUserManagement'
                    aria-expanded='false'
                    aria-controls='collapseUserManagement'
                  >
                    <Icon icon='users' className='me-2' /> User Management
                  </button>
                </h2>
                <div
                  id='collapseUserManagement'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingUserManagement'
                  data-bs-parent='#sidebarAccordion'
                >
                  <div className='accordion-body'>
                    <a
                      href='#suppliers'
                      className={`text-decoration-none d-block ${
                        selectedPage === "user-management-suppliers"
                          ? "active-link"
                          : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() =>
                        setSelectedPage("user-management-suppliers")
                      }
                    >
                      Suppliers
                    </a>
                    <a
                      href='#buyers'
                      className={`text-decoration-none d-block ${
                        selectedPage === "user-management-buyers"
                          ? "active-link"
                          : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("user-management-buyers")}
                    >
                      Buyers
                    </a>
                    <a
                      href='#admins'
                      className={`text-decoration-none d-block ${
                        selectedPage === "user-management-admins"
                          ? "active-link"
                          : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("user-management-admins")}
                    >
                      Admins
                    </a>
                  </div>
                </div>
              </div>

              {/* Products Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingProducts'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseProducts'
                    aria-expanded='false'
                    aria-controls='collapseProducts'
                  >
                    <Icon icon='box' className='me-2' /> Products
                  </button>
                </h2>
                <div
                  id='collapseProducts'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingProducts'
                  data-bs-parent='#sidebarAccordion'
                >
                  <div className='accordion-body'>
                    <a
                      href='#products'
                      className={`text-decoration-none d-block ${
                        selectedPage === "products" ? "active-link" : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("products")}
                    >
                      Products
                    </a>
                    <a
                      href='#options-library'
                      className={`text-decoration-none d-block ${
                        selectedPage === "options-library" ? "active-link" : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("options-library")}
                    >
                      Options Library
                    </a>
                  </div>
                </div>
              </div>

              {/* Orders Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingOrders'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseOrders'
                    aria-expanded='false'
                    aria-controls='collapseOrders'
                  >
                    <Icon icon='shopping-bag' className='me-2' /> Orders
                  </button>
                </h2>
                <div
                  id='collapseOrders'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingOrders'
                  data-bs-parent='#sidebarAccordion'
                >
                  <div className='accordion-body'>
                    <a
                      href='#orders'
                      className={`text-decoration-none d-block ${
                        selectedPage === "orders" ? "active-link" : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("orders")}
                    >
                      Orders
                    </a>
                    <a
                      href='#abandoned-carts'
                      className={`text-decoration-none d-block ${
                        selectedPage === "abandoned-carts" ? "active-link" : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("abandoned-carts")}
                    >
                      Abandoned Carts
                    </a>
                  </div>
                </div>
              </div>

              {/* Analytics Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingAnalytics'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseAnalytics'
                    aria-expanded='false'
                    aria-controls='collapseAnalytics'
                  >
                    <Icon icon='bar-chart-2' className='me-2' /> Analytics
                  </button>
                </h2>
                <div
                  id='collapseAnalytics'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingAnalytics'
                  data-bs-parent='#sidebarAccordion'
                >
                  <div className='accordion-body'>
                    <a
                      href='#analytics-overview'
                      className={`text-decoration-none d-block ${
                        selectedPage === "analytics-overview"
                          ? "active-link"
                          : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("analytics-overview")}
                    >
                      Overview
                    </a>
                    <a
                      href='#live-analytics'
                      className={`text-decoration-none d-block d-flex justify-content-between ${
                        selectedPage === "live-analytics" ? "active-link" : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("live-analytics")}
                    >
                      Live Analytics
                    </a>
                    <a
                      href='#analytics-reports'
                      className={`text-decoration-none d-block ${
                        selectedPage === "analytics-reports"
                          ? "active-link"
                          : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("analytics-reports")}
                    >
                      Reports
                    </a>
                  </div>
                </div>
              </div>

              {/* Marketing Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingMarketing'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseMarketing'
                    aria-expanded='false'
                    aria-controls='collapseMarketing'
                  >
                    <Icon icon='layers' className='me-2' /> Marketing
                  </button>
                </h2>
                <div
                  id='collapseMarketing'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingMarketing'
                  data-bs-parent='#sidebarAccordion'
                >
                  <div className='accordion-body'>
                    <a
                      href='#seo-enhancements'
                      className={`text-decoration-none d-block ${
                        selectedPage === "seo-enhancements" ? "active-link" : ""
                      }`}
                      style={{ color: "#2c6449" }}
                      onClick={() => setSelectedPage("seo-enhancements")}
                    >
                      SEO Enhancements
                    </a>
                  </div>
                </div>
              </div>

              {/* Settings Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingSettings'>
                  <button
                    className={`accordion-button collapsed ${
                      selectedPage === "settings" ? "active-link" : ""
                    }`}
                    type='button'
                    onClick={() => setSelectedPage("settings")}
                  >
                    <Icon icon='settings' className='me-2' /> Settings
                  </button>
                </h2>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className='col-md-9 py-4'>{renderContent()}</div>
      </div>
    </div>
  );
};

export default AdminDashboard;
