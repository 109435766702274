import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/config"; // Import Firebase config
import { collection, getDocs } from "firebase/firestore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import constructionImg from "../assets/construction.webp";
import equipmentImg from "../assets/equipment.webp";
import plasticPaperImg from "../assets/plasticandpapers.webp";
import saudimanufacturedImg from "../assets/saudimanufactured.webp";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [products, setProducts] = useState([]); // State to hold fetched products
  const [loading, setLoading] = useState(true); // State for loading status

  const navigate = useNavigate();
  const sliderRef = useRef(null); // Ref for controlling the slider

  const { t, i18n } = useTranslation(); // Extract i18n
  const locale = i18n.language; // Current language (e.g., "en" or "ar")
  const currencySymbol = locale === "ar" ? "ر.س." : "SR "; // Currency symbol

  const formatNumber = (number, locale) => {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(
      number
    );
  };

  // Fetch data from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products")); // Replace 'products' with your Firestore collection name
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList); // Update state with fetched data
        setLoading(false); // Turn off loading spinner
      } catch (error) {
        console.error("Error fetching products: ", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable default arrows to use custom ones
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div>
      {/* Hero Section */}
      <section
        style={{
          backgroundImage: `url('/banner.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          padding: "100px 0",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(44, 100, 73, 0.85)",
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            padding: "0 15px",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>
              {t("hero.hero_title")}
            </h1>
            <p style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>
              {t("hero.hero_paragraph1")}
            </p>
            <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
              {t("hero.hero_paragraph2")}
            </p>
          </div>
        </div>
      </section>

      {/* Category Section */}
      <section className='py-4 bg-white'>
        <h2 className='text-center mb-4'>{t("hero.shop_by_category")}</h2>
        <div className='container'>
          <div className='row g-3'>
            <div
              className='col-12 col-sm-6 col-lg-3 text-center'
              onClick={() => navigate("/construction")}
            >
              <img
                src={constructionImg}
                alt={t("hero.construction")}
                className='img-fluid rounded'
                style={{ cursor: "pointer", objectFit: "cover" }}
              />
              <p>{t("hero.construction")}</p>
            </div>
            <div
              className='col-12 col-sm-6 col-lg-3 text-center'
              onClick={() => navigate("/equipment")}
            >
              <img
                src={equipmentImg}
                alt={t("hero.equipment")}
                className='img-fluid rounded'
                style={{ cursor: "pointer", objectFit: "cover" }}
              />
              <p>{t("hero.equipment")}</p>
            </div>
            <div
              className='col-12 col-sm-6 col-lg-3 text-center'
              onClick={() => navigate("/plastic-and-papers")}
            >
              <img
                src={plasticPaperImg}
                alt={t("hero.plastic_and_papers")}
                className='img-fluid rounded'
                style={{ cursor: "pointer", objectFit: "cover" }}
              />
              <p>{t("hero.plastic_and_papers")}</p>
            </div>
            <div
              className='col-12 col-sm-6 col-lg-3 text-center'
              onClick={() => navigate("/saudi-manufactured")}
            >
              <img
                src={saudimanufacturedImg}
                alt={t("hero.saudi_manufactured")}
                className='img-fluid rounded'
                style={{ cursor: "pointer", objectFit: "cover" }}
              />
              <p>{t("hero.saudi_manufactured")}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Best Sellers Section */}
      <section
        className='py-4 bg-light'
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h2 className='m-0 text-success'>{t("hero.best_sellers")}</h2>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='text-success fw-bold border-bottom border-success pb-1'
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/products")}
            >
              {t("hero.all_products")}
            </span>
            <button
              className='btn btn-outline-success rounded-circle p-0 d-flex justify-content-center align-items-center'
              style={{ width: "40px", height: "40px" }}
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &#8592;
            </button>
            <button
              className='btn btn-outline-success rounded-circle p-0 d-flex justify-content-center align-items-center'
              style={{ width: "40px", height: "40px" }}
              onClick={() => sliderRef.current?.slickNext()}
            >
              &#8594;
            </button>
          </div>
        </div>
        {!loading && products.length > 0 && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {products.map((product) => {
              const priceRanges = product.priceRanges || [];
              const prices = priceRanges.map((range) => range.price);
              const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
              const highestPrice = prices.length ? Math.max(...prices) : "N/A";
              const minOrder = priceRanges[0]?.minQty || "N/A";
              const mainImage =
                product.mainImageUrl || "https://via.placeholder.com/300";
              const category = product.category || t("uncategorized");

              return (
                <div key={product.id} className='p-2'>
                  <div
                    className='card shadow-sm border-0 h-100 d-flex flex-column'
                    style={{ minHeight: "350px" }}
                  >
                    {/* Product Image */}
                    <div
                      className='card-img-top overflow-hidden'
                      style={{ height: "200px" }}
                    >
                      <img
                        src={mainImage}
                        alt={product.description || t("hero.unnamed_product")}
                        className='img-fluid w-100 h-100'
                        style={{ objectFit: "cover" }}
                      />
                    </div>

                    {/* Product Details */}
                    <div className='card-body text-center d-flex flex-column justify-content-between'>
                      <div>
                        <p className='text-muted small mb-1'>{category}</p>
                        <h5 className='card-title mb-2 text-truncate text-capitalize'>
                          {product.productName || t("hero.unnamed_product")}
                        </h5>
                        <p className='fw-bold mb-1'>
                          {t("hero.price_range", {
                            lowestPrice: `${currencySymbol}${formatNumber(
                              lowestPrice,
                              locale
                            )}`,
                            highestPrice: `${currencySymbol}${formatNumber(
                              highestPrice,
                              locale
                            )}`,
                          })}
                        </p>
                        <p className='text-muted small mb-3'>
                          {t("hero.min_order", { minOrder })}
                        </p>
                      </div>
                      <div className='d-flex flex-column align-items-center gap-2'>
                        {/* Align buttons vertically with uniform width */}
                        <button
                          onClick={() => navigate(`/product/${product.id}`)}
                          className='btn btn-success btn-sm'
                          style={{ width: "150px" }}
                        >
                          {t("hero.view_details")}
                        </button>
                        <button
                          className='btn btn-primary btn-sm'
                          style={{ width: "150px" }}
                        >
                          {t("hero.contact_supplier")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </section>
    </div>
  );
};

export default Home;
