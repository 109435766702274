import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { db } from "../firebase/config";
import { collection, addDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../context/AuthContext"; //
import Notification from "../components/shared/Notification";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import {
  PrimaryButton,
  SecondaryButton,
  DangerButton,
} from "../components/shared/StyledComponents";

const SupplierAddProducts = () => {
  const { currentUser } = useAuth();
  const [username, setUsername] = useState("No User");
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryLabel, setSubCategoryLabel] = useState("");
  const [categories, setCategories] = useState({});
  const [showSubCategoryLabel, setShowSubCategoryLabel] = useState(false);
  const [description, setDescription] = useState("");
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([null]);
  const [priceRanges, setPriceRanges] = useState([
    {
      minQty: "",
      maxQty: "",
      price: "",
      locations: [{ location: "", locationPrice: "" }],
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [mainLocation, setMainLocation] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handleSubCategoryChange = (selected) => {
    const selectedValue = selected?.value || "";
    setSubCategory(selectedValue);

    // Show "Sub Category Label" input if "Others" is selected
    if (selectedValue === "Others") {
      setShowSubCategoryLabel(true);
    } else {
      setShowSubCategoryLabel(false);
      setSubCategoryLabel(""); // Clear the input when not needed
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productCollection = collection(db, "products");
        const productSnapshot = await getDocs(productCollection);

        const categoryData = {};
        productSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const category = data.category || "Uncategorized";
          const subCategory = data.subCategory || "Uncategorized";

          if (!categoryData[category]) {
            categoryData[category] = new Set();
          }
          categoryData[category].add(subCategory);
        });

        // Add "Others" to each category
        const formattedCategories = {};
        for (const category in categoryData) {
          formattedCategories[category] = Array.from(categoryData[category]);
          if (!formattedCategories[category].includes("Others")) {
            formattedCategories[category].push("Others");
          }
        }

        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching categories and subcategories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch Username from Firestore
  useEffect(() => {
    const fetchUsername = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid)); // Fetch user document
          if (userDoc.exists()) {
            setUsername(userDoc.data().name || "User"); // Set username if it exists
          } else {
            console.warn("No user data found for this user.");
          }
        } catch (error) {
          console.error("Error fetching username: ", error);
        }
      }
    };

    fetchUsername(); // Call fetch function
  }, [currentUser]);

  const defaultPriceOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "Unlimited", label: "Unlimited" },
  ];

  const defaultSizeOptions = [
    { value: "XS", label: "XS" },
    { value: "S", label: "S" },
    { value: "M", label: "M" },
    { value: "L", label: "L" },
    { value: "XL", label: "XL" },
    { value: "XXL", label: "XXL" },
  ];

  const defaultColorOptions = [
    { value: "Red", label: "Red" },
    { value: "Blue", label: "Blue" },
    { value: "Green", label: "Green" },
    { value: "Yellow", label: "Yellow" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "Pink", label: "Pink" },
    { value: "Purple", label: "Purple" },
    { value: "Orange", label: "Orange" },
    { value: "Gray", label: "Gray" },
  ];

  const defaultQtyOptions = Array.from({ length: 100 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  })).concat({ value: "Unlimited", label: "Unlimited" });

  const defaultLocationOptions = [
    "Riyadh",
    "Jeddah",
    "Mecca",
    "Medina",
    "Dammam",
    "Khobar",
    "Tabuk",
    "Abha",
    "Khamis Mushait",
    "Buraidah",
    "Najran",
    "Al Hufuf",
    "Yanbu",
    "Al Jubail",
    "Al Khafji",
    "Arar",
    "Sakaka",
    "Hafar Al-Batin",
    "Qatif",
    "Al Bahah",
    "Jizan",
    "Al Majma'ah",
    "Al Zulfi",
    "Unaizah",
    "Rabigh",
    "Ras Tanura",
    "Safwa",
    "Turubah",
    "Turaif",
    "Wadi ad-Dawasir",
  ].map((city) => ({ value: city, label: city }));

  const [locationOptions, setLocationOptions] = useState(
    defaultLocationOptions
  );

  const [sizeOptions, setSizeOptions] = useState(defaultSizeOptions);
  const [colorOptions, setColorOptions] = useState(defaultColorOptions);
  const [qtyOptions, setQtyOptions] = useState(defaultQtyOptions);

  const handleCreateQtyOption = (newOption) => {
    if (!qtyOptions.some((opt) => opt.value === newOption)) {
      const newOptionObj = { value: newOption, label: newOption };
      setQtyOptions((prevOptions) => [...prevOptions, newOptionObj]);
      return newOptionObj;
    }
    return null;
  };

  const handleAddLocation = (priceRangeIndex) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[priceRangeIndex].locations.push({
      location: "",
      locationPrice: "",
    });
    setPriceRanges(updatedRanges);
  };

  const handleLocationChange = (
    priceRangeIndex,
    locationIndex,
    field,
    value
  ) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[priceRangeIndex].locations[locationIndex][field] = value;
    setPriceRanges(updatedRanges);
  };

  const handleRemoveLocation = (priceRangeIndex, locationIndex) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[priceRangeIndex].locations.splice(locationIndex, 1);
    setPriceRanges(updatedRanges);
  };

  const handleAddAdditionalImage = () => {
    setAdditionalImages([...additionalImages, null]);
  };

  const handleAdditionalImageChange = (index, file) => {
    const updatedImages = [...additionalImages];
    updatedImages[index] = file;
    setAdditionalImages(updatedImages);
  };

  const handleRemoveAdditionalImage = (index) => {
    const updatedImages = [...additionalImages];
    updatedImages.splice(index, 1);
    setAdditionalImages(updatedImages);
  };

  const handleAddPriceRange = () => {
    setPriceRanges([
      ...priceRanges,
      {
        minQty: "",
        maxQty: "",
        price: "",
        locations: [{ location: "", locationPrice: "" }], // Ensure locations array is initialized
      },
    ]);
  };

  const handlePriceRangeChange = (index, field, value) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[index][field] = value;
    setPriceRanges(updatedRanges);
  };

  const handleRemovePriceRange = (index) => {
    const updatedRanges = [...priceRanges];
    updatedRanges.splice(index, 1);
    setPriceRanges(updatedRanges);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      showNotification(
        "Authentication Error",
        "You need to log in to upload a product."
      );
      return;
    }

    // Validate required fields
    if (
      !productName ||
      !category ||
      !subCategory ||
      !description ||
      sizes.length === 0 ||
      colors.length === 0 ||
      !mainImage ||
      !mainLocation || // Add this line
      priceRanges.length === 0
    ) {
      showNotification(
        "Validation Error",
        "Please fill in all required fields before submitting."
      );
      return;
    }

    setLoading(true); // Start loading spinner
    setMessage({ type: "", text: "" });

    try {
      const storage = getStorage();

      // Upload main image
      const mainImageRef = ref(storage, `images/${mainImage.name}`);
      await uploadBytes(mainImageRef, mainImage);
      const mainImageUrl = await getDownloadURL(mainImageRef);

      // Upload additional images
      const additionalImageUrls = [];
      for (const file of additionalImages) {
        if (file) {
          const imageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(imageRef, file);
          const imageUrl = await getDownloadURL(imageRef);
          additionalImageUrls.push(imageUrl);
        }
      }

      // Save to Firestore
      await addDoc(collection(db, "products"), {
        productName,
        category,
        subCategory,
        description,
        sizes: sizes.map((size) => size.value),
        colors: colors.map((color) => color.value),
        mainImageUrl,
        additionalImageUrls,
        mainLocation: mainLocation ? mainLocation.value : null, // Add this line
        priceRanges: priceRanges.map((range) => ({
          ...range,
          locations: range.locations.map((loc) => ({
            location: loc.location,
            locationPrice: loc.locationPrice
              ? parseFloat(loc.locationPrice)
              : 0,
          })),
        })),
        createdAt: new Date(),
        supplierId: currentUser?.uid, // Add supplierId
        supplierName: username, // Add supplierName
      });

      showNotification(
        "Success",
        "Your product has been uploaded successfully."
      );

      // Reset the form
      setProductName("");
      setCategory("");
      setSubCategory("");
      setDescription("");
      setSizes([]);
      setColors([]);
      setMainImage(null);
      setAdditionalImages([]);
      setPriceRanges([
        {
          minQty: "",
          maxQty: "",
          price: "",
          locations: [{ location: "", locationPrice: "" }],
        },
      ]);
    } catch (error) {
      console.error("Error adding product: ", error);
      showNotification(
        "Submission Error",
        "Failed to upload the product. Please try again later."
      );
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleCreateOption = (newOption, currentOptions, setOptions) => {
    const isDuplicate = currentOptions.some(
      (option) => option.value.toLowerCase() === newOption.toLowerCase()
    );
    if (isDuplicate) {
      setMessage({
        type: "warning",
        text: `The option "${newOption}" already exists.`,
      });
      return;
    }
    const newOptionObj = { value: newOption, label: newOption };
    setOptions([...currentOptions, newOptionObj]);
    return newOptionObj;
  };

  const handleCreateLocationOption = (newLocation) => {
    const newOption = { value: newLocation, label: newLocation };
    setLocationOptions([...locationOptions, newOption]);
    return newOption;
  };

  return (
    <div className='container mt-3'>
      <h2 className='mb-3'>Upload Product</h2>
      {loading && <LoadingSpinner />}
      {message.text && (
        <div className={`alert alert-${message.type} mt-2`} role='alert'>
          {message.text}
        </div>
      )}

      <form onSubmit={handleSubmit} className='mt-3'>
        <div className='row g-2'>
          <div className='col-md-3'>
            <label htmlFor='productName' className='form-label'>
              Product Name
            </label>
            <input
              type='text'
              className='form-control'
              id='productName'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>

          <div className='col-md-3'>
            <label htmlFor='category' className='form-label'>
              Category
            </label>
            <select
              className='form-control'
              id='category'
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                setSubCategory(""); // Reset subcategory when category changes
              }}
              required
            >
              <option value=''>Select a Category</option>
              {Object.keys(categories).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>

          <div className='col-md-3'>
            <label htmlFor='subCategory' className='form-label'>
              Sub Category
            </label>
            <Select
              options={(categories[category] || []).map((sub) => ({
                value: sub,
                label: sub,
              }))}
              value={
                subCategory ? { value: subCategory, label: subCategory } : null
              }
              onChange={handleSubCategoryChange}
              placeholder='Select a subcategory'
            />
          </div>

          {showSubCategoryLabel && (
            <div className='col-md-3'>
              <label htmlFor='subCategoryLabel' className='form-label'>
                Sub Category Label
              </label>
              <input
                type='text'
                className='form-control'
                id='subCategoryLabel'
                value={subCategoryLabel}
                onChange={(e) => setSubCategoryLabel(e.target.value)}
                placeholder='Enter sub category label'
                required
              />
            </div>
          )}

          <div className='row g-2 mt-2'>
            <div className='col-md-3'>
              <label htmlFor='description' className='form-label'>
                Description
              </label>
              <input
                type='text'
                className='form-control'
                id='description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div className='col-md-3'>
              <label htmlFor='mainLocation' className='form-label'>
                Main Location
              </label>
              <CreatableSelect
                options={locationOptions}
                value={mainLocation}
                onChange={(selected) => setMainLocation(selected)}
                onCreateOption={(newLocation) => {
                  const newOption = handleCreateOption(
                    newLocation,
                    locationOptions,
                    setLocationOptions
                  );
                  if (newOption) setMainLocation(newOption);
                }}
                placeholder='Select or create a location'
              />
            </div>
            <div className='col-md-3'>
              <label htmlFor='sizes' className='form-label'>
                Sizes
              </label>
              <CreatableSelect
                options={sizeOptions}
                isMulti
                value={sizes}
                onChange={(selected) => setSizes(selected)}
                onCreateOption={(newSize) => {
                  const newSizeObj = handleCreateOption(
                    newSize,
                    sizeOptions,
                    setSizeOptions
                  );
                  if (newSizeObj) setSizes([...sizes, newSizeObj]);
                }}
                classNamePrefix='select'
                placeholder='Select or create a Size'
              />
            </div>
            <div className='col-md-3'>
              <label htmlFor='colors' className='form-label'>
                Colors
              </label>
              <CreatableSelect
                options={colorOptions}
                isMulti
                value={colors}
                onChange={(selected) => setColors(selected)}
                onCreateOption={(newColor) => {
                  const newColorObj = handleCreateOption(
                    newColor,
                    colorOptions,
                    setColorOptions
                  );
                  if (newColorObj) setColors([...colors, newColorObj]);
                }}
                classNamePrefix='select'
                placeholder='Select or create a Color'
              />
            </div>
          </div>

          <div className='row g-2 mt-2 align-items-start'>
            {/* Main Image */}
            <div className='col-md-6'>
              <label htmlFor='mainImage' className='form-label'>
                Main Image
              </label>
              <input
                type='file'
                className='form-control'
                id='mainImage'
                accept='image/*'
                onChange={(e) => setMainImage(e.target.files[0])}
                required
              />
            </div>

            {/* Additional Images */}
            <div className='col-md-6'>
              <label className='form-label'>Additional Images</label>
              {additionalImages.map((_, index) => (
                <div key={index} className='d-flex mb-1 align-items-center'>
                  <input
                    type='file'
                    className='form-control me-2'
                    accept='image/*'
                    onChange={(e) =>
                      handleAdditionalImageChange(index, e.target.files[0])
                    }
                  />
                  <DangerButton
                    type='button'
                    className='btn btn-secondary btn-sm'
                    onClick={() => handleRemoveAdditionalImage(index)}
                  >
                    Remove
                  </DangerButton>
                </div>
              ))}
              <SecondaryButton
                type='button'
                className='btn btn-secondary btn-sm'
                onClick={handleAddAdditionalImage}
              >
                Add Another Image
              </SecondaryButton>
            </div>
          </div>

          <div className='col-md-12 mt-3'>
            {priceRanges.map((range, index) => (
              <div key={index} className='mb-4'>
                <div className='row g-3 align-items-start'>
                  {/* Price Ranges Section */}
                  <div className='col-md-6'>
                    <h6 className='fw-bold'>Price Range</h6>
                    <div className='row g-2'>
                      <div className='col-md-4'>
                        <CreatableSelect
                          options={qtyOptions}
                          value={
                            range.minQty
                              ? { value: range.minQty, label: range.minQty }
                              : null
                          }
                          onChange={(selected) =>
                            handlePriceRangeChange(
                              index,
                              "minQty",
                              selected?.value || ""
                            )
                          }
                          onCreateOption={(newQty) => {
                            const newOption = handleCreateQtyOption(newQty);
                            if (newOption)
                              handlePriceRangeChange(
                                index,
                                "minQty",
                                newOption.value
                              );
                          }}
                          placeholder='Min Qty'
                        />
                      </div>
                      <div className='col-md-4'>
                        <CreatableSelect
                          options={qtyOptions}
                          value={
                            range.maxQty
                              ? { value: range.maxQty, label: range.maxQty }
                              : null
                          }
                          onChange={(selected) =>
                            handlePriceRangeChange(
                              index,
                              "maxQty",
                              selected?.value || ""
                            )
                          }
                          onCreateOption={(newQty) => {
                            const newOption = handleCreateQtyOption(newQty);
                            if (newOption)
                              handlePriceRangeChange(
                                index,
                                "maxQty",
                                newOption.value
                              );
                          }}
                          placeholder='Max Qty'
                        />
                      </div>

                      <div className='col-md-4'>
                        <CreatableSelect
                          options={defaultPriceOptions}
                          value={
                            range.price
                              ? { value: range.price, label: range.price }
                              : null
                          }
                          onChange={(selected) =>
                            handlePriceRangeChange(
                              index,
                              "price",
                              selected?.value || ""
                            )
                          }
                          onCreateOption={(newValue) => {
                            // Allow empty values
                            if (newValue === "") {
                              handlePriceRangeChange(index, "price", "");
                              return;
                            }

                            const isNumber =
                              !isNaN(newValue) && Number(newValue) >= 0; // Include "0"
                            const isUnlimited =
                              newValue.toLowerCase() === "unlimited";

                            if (isNumber || isUnlimited) {
                              handlePriceRangeChange(index, "price", newValue);
                            } else {
                              showNotification(
                                "Error",
                                "Invalid input. Enter '0', a positive number, or 'Unlimited'."
                              );
                            }
                          }}
                          isClearable // Enable clearing the input
                          placeholder='Price'
                        />
                      </div>
                    </div>
                    <DangerButton
                      type='button'
                      className='btn btn-danger btn-sm mt-2'
                      onClick={() => handleRemovePriceRange(index)}
                    >
                      Remove Price Range
                    </DangerButton>
                  </div>

                  {/* Locations Section */}
                  <div className='col-md-6'>
                    <h6 className='fw-bold'>Locations</h6>
                    {range.locations?.map((loc, locIndex) => (
                      <div
                        key={locIndex}
                        className='row g-2 align-items-center mb-2'
                      >
                        <div className='col-md-6'>
                          <CreatableSelect
                            options={locationOptions}
                            value={
                              loc.location
                                ? { value: loc.location, label: loc.location }
                                : null
                            }
                            onChange={(selected) =>
                              handleLocationChange(
                                index,
                                locIndex,
                                "location",
                                selected?.value || ""
                              )
                            }
                            onCreateOption={(newOption) => {
                              const newLoc =
                                handleCreateLocationOption(newOption);
                              handleLocationChange(
                                index,
                                locIndex,
                                "location",
                                newLoc.value
                              );
                            }}
                            placeholder='Select or create a location'
                          />
                        </div>
                        <div className='col-md-5'>
                          <CreatableSelect
                            options={defaultPriceOptions}
                            value={
                              loc.locationPrice
                                ? {
                                    value: loc.locationPrice,
                                    label: loc.locationPrice,
                                  }
                                : null
                            }
                            onChange={(selected) =>
                              handleLocationChange(
                                index,
                                locIndex,
                                "locationPrice",
                                selected?.value || ""
                              )
                            }
                            onCreateOption={(newValue) => {
                              // Allow empty values
                              if (newValue === "") {
                                handleLocationChange(
                                  index,
                                  locIndex,
                                  "locationPrice",
                                  ""
                                );
                                return;
                              }

                              const isNumber =
                                !isNaN(newValue) && Number(newValue) >= 0; // Include "0"
                              const isUnlimited =
                                newValue.toLowerCase() === "unlimited";

                              if (isNumber || isUnlimited) {
                                handleLocationChange(
                                  index,
                                  locIndex,
                                  "locationPrice",
                                  newValue
                                );
                              } else {
                                showNotification(
                                  "Error",
                                  "Invalid input. Enter '0', a positive number, or 'Unlimited'."
                                );
                              }
                            }}
                            isClearable // Enable clearing the input
                            placeholder='Location Price'
                          />
                        </div>

                        <div className='col-md-1'>
                          <DangerButton
                            type='button'
                            onClick={() =>
                              handleRemoveLocation(index, locIndex)
                            }
                          >
                            Remove
                          </DangerButton>
                        </div>
                      </div>
                    ))}
                    <SecondaryButton
                      type='button'
                      className='btn btn-secondary btn-sm'
                      onClick={() => handleAddLocation(index)}
                    >
                      Add Location
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            ))}
            <SecondaryButton
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleAddPriceRange}
            >
              Add Price Range
            </SecondaryButton>
          </div>
        </div>

        <div className='mt-5'>
          <PrimaryButton
            type='submit'
            className='btn btn-primary btn-md'
            disabled={loading} // Disable button during loading
          >
            {loading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>{" "}
                Uploading...
              </>
            ) : (
              "Upload Product"
            )}
          </PrimaryButton>
        </div>
      </form>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)} // Close handler
        title={notificationContent.title}
        message={notificationContent.message}
        duration={3000} // Auto-close after 3 seconds
      />
    </div>
  );
};

export default SupplierAddProducts;
