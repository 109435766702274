import React from "react";
import ReactDOM from "react-dom/client"; // Import from react-dom/client for createRoot
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext"; // Import the CartProvider
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import "./i18n";

// Create the root and render the App component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AuthProvider>
      <CartProvider>
        {" "}
        {/* Wrap App with CartProvider */}
        <App />
      </CartProvider>
    </AuthProvider>
  </Router>
);
