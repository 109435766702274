import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDown } from "react-feather";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { useAuth } from "../context/AuthContext";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function CheckOutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [showTooltip, setShowTooltip] = useState(false);
  const [userName, setUserName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMap, setShowMap] = useState(false); // State to control map visibility
  const tooltipRef = useRef(null);

  const cartItems = useMemo(
    () => location.state?.cartItems || [],
    [location.state]
  );

  const toggleTooltip = () => setShowTooltip((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!cartItems.length) {
      navigate("/cart");
    }
  }, [cartItems, navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser?.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserName(userData?.name || "Unknown User");
            setUserAddress(userData?.address || "");
          } else {
            console.warn("User document does not exist.");
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchUserDetails();
  }, [currentUser]);

  const handleMapClick = (event) => {
    const location = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(location);
    setUserAddress(`Lat: ${location.lat}, Lng: ${location.lng}`); // Update user address
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    marginTop: "20px",
  };

  const defaultCenter = {
    lat: 24.7136, // Riyadh latitude
    lng: 46.6753, // Riyadh longitude
  };

  return (
    <div className='container mt-4'>
      {/* Secure Checkout Header */}
      <h2
        className='text-center'
        style={{
          fontSize: "28px",
          backgroundColor: "#2c6449",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
          position: "relative",
        }}
      >
        Secure Checkout
        <ChevronDown
          onClick={(e) => {
            e.stopPropagation();
            toggleTooltip();
          }}
          style={{
            marginLeft: "10px",
            cursor: "pointer",
            fontSize: "20px",
            color: "white",
          }}
        />
        {showTooltip && (
          <div
            ref={tooltipRef}
            style={{
              position: "absolute",
              top: "110%",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#f8f9fa",
              color: "#333",
              padding: "15px",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              width: "400px",
              fontSize: "12px",
              textAlign: "center",
              zIndex: "1000",
            }}
          >
            We secure your payment and personal information when you share or
            save it with us. We don't share payment details with third-party
            sellers. We don't sell your information to others.{" "}
            <button
              onClick={() => alert("Learn more action")}
              style={{
                color: "#007bff",
                textDecoration: "none",
                fontWeight: "bold",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: 0,
              }}
            >
              Learn more
            </button>
          </div>
        )}
      </h2>

      <div className='row'>
        {/* Left Column */}
        <div className='col-md-8'>
          {/* Delivery Info */}
          <div className='card mb-4'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <h5>Delivering to</h5>
              <button
                className='btn btn-link p-0'
                onClick={() => setShowMap((prev) => !prev)}
              >
                {showMap ? "Hide Map" : "Change"}
              </button>
            </div>
            <div className='card-body'>
              <p>
                <strong>{userName}</strong>
              </p>
              <p>{userAddress || "No delivery address available."}</p>
              {showMap && (
                <div style={mapContainerStyle}>
                  <LoadScript googleMapsApiKey='AIzaSyD92sPOOqCshhZW-rQdS71XohnOMRqOsG8'>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={selectedLocation || defaultCenter}
                      zoom={12}
                      onClick={handleMapClick}
                    >
                      {selectedLocation && (
                        <Marker position={selectedLocation} />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </div>
              )}
            </div>
          </div>

          {/* Order Details */}
          <div className='card mb-4'>
            <div className='card-header'>
              <h5>Order Details</h5>
            </div>
            <div className='card-body'>
              <div className='mb-3'>
                <h6>Items in your order:</h6>
                <table className='table table-bordered'>
                  <thead className='thead-light'>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Shipping</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.cartId}>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {item.price.toFixed(2)} {item.currency || "SR"}
                        </td>
                        <td>
                          {item.shippingCost?.toFixed(2) || "0.00"}{" "}
                          {item.currency || "SR"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-3'>
                <h6>Delivery Date:</h6>
                <p>
                  Estimated delivery on <strong>January 25, 2025</strong>
                </p>
              </div>
            </div>
          </div>

          {/* Payment Method */}
          <div className='card mb-4'>
            <div className='card-header'>
              <h5>Payment Method</h5>
            </div>
            <div className='card-body'>
              <div className='mb-3'>
                <label className='form-label'>
                  Enter a gift card or promotional code
                </label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter code'
                  />
                  <button className='btn btn-primary'>Apply</button>
                </div>
              </div>
              <h6>Your credit and debit cards</h6>
              <button className='btn btn-outline-secondary w-100 mb-3'>
                Add a credit or debit card
              </button>
              <h6>Buy Now, Pay Later</h6>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='payment'
                  id='tabby'
                />
                <label className='form-check-label' htmlFor='tabby'>
                  Pay Over time with Tabby - 0% Interest. No hidden charges.
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='payment'
                  id='tamara'
                />
                <label className='form-check-label' htmlFor='tamara'>
                  Pay Over time with Tamara - 0% Interest. No hidden charges.
                </label>
              </div>
              <h6 className='mt-3'>Other payment options</h6>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='payment'
                  id='cod'
                  disabled
                />
                <label className='form-check-label text-muted' htmlFor='cod'>
                  Cash on Delivery (COD) - Not available for this order
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className='col-md-4'>
          <div className='card'>
            <div className='card-header'>
              <h5>Order Summary</h5>
            </div>
            <div className='card-body'>
              <p>
                <strong>Item/s Cost:</strong>
                <span className='float-end'>
                  {cartItems
                    .reduce((acc, item) => acc + item.quantity * item.price, 0)
                    .toFixed(2)}{" "}
                  {cartItems[0]?.currency || "SR"}
                </span>
              </p>
              <p>
                <strong>Shipping:</strong>
                <span className='float-end'>
                  {cartItems
                    .reduce((acc, item) => acc + (item.shippingCost || 0), 0)
                    .toFixed(2)}{" "}
                  {cartItems[0]?.currency || "SR"}
                </span>
              </p>
              <p>
                <strong>Vat Cost (15%):</strong>
                <span className='float-end'>
                  {cartItems
                    .reduce(
                      (acc, item) => acc + item.quantity * item.price * 0.15,
                      0
                    )
                    .toFixed(2)}{" "}
                  {cartItems[0]?.currency || "SR"}
                </span>
              </p>
              <hr />
              <p>
                <strong>Total Cost:</strong>
                <span className='float-end'>
                  {cartItems
                    .reduce(
                      (acc, item) =>
                        acc +
                        item.quantity * item.price +
                        (item.shippingCost || 0) +
                        item.quantity * item.price * 0.15,
                      0
                    )
                    .toFixed(2)}{" "}
                  {cartItems[0]?.currency || "SR"}
                </span>
              </p>
              <button
                className='btn btn-primary w-100 mt-3'
                onClick={() => alert("Checkout Confirmed!")}
              >
                Confirm and Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckOutPage;
